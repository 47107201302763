<mat-toolbar
  color="primary"
  class="set-width top-bar"
  fxLayout="row"
  fxLayoutGap="5px"
  [class.desktop]="isLargeBreakpoint$ | async"
>
  <button
    *ngIf="!isTemporary()"
    class="main-menu-icon"
    mat-icon-button
    (click)="sidenav.toggle()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <span class="portal-title">{{ title }}</span>
  <div fxFlex></div>
  <button
    *ngIf="!isTemporary()"
    mat-icon-button
    (click)="notificationSidenav.toggle()"
  >
    <mat-icon
      [matBadge]="notificationsCount$ | async"
      [matBadgeHidden]="!(notificationsCount$ | async)"
      >notifications</mat-icon
    >
  </button>
  <img
    [src]="
      'https://lfsstatics.z33.web.core.windows.net/' + hostname + '/logo.png'
    "
    class="logo"
    onerror="this.style.display='none'"
  />
</mat-toolbar>

<mat-sidenav-container
  fxFlex
  class="set-width width-full"
  [class.desktop]="isLargeBreakpoint$ | async"
>
  <mat-sidenav
    #sidenav
    *ngIf="!isTemporary()"
    [mode]="(isLargeBreakpoint$ | async) ? 'side' : 'over'"
    [opened]="isLargeBreakpoint$ | async"
    style="border-right: 1px solid #eee"
  >
    <div fxLayout="row" class="height-full">
      <div fxFlex fxLayout="column">
        <mat-toolbar
          class="text-overflow-ellipsis"
          fxLayout="column"
          fxLayoutAlign="center stretch"
        >
          <div></div>
          <div class="subheading-1">Welcome</div>
          <div
            class="mat-small text-overflow-ellipsis"
            *ngIf="profile$ | async as profile"
            [title]="profile['preferred_username'] || profile['name']"
          >
            {{ profile["preferred_username"] || profile["name"] }}
          </div>
        </mat-toolbar>
        <mat-divider></mat-divider>
        <mat-nav-list fxFlex fxLayout="column">
          <mat-list-item (click)="listItemClick()" routerLink="/tasks">
            <mat-icon
              matListIcon
              [matBadge]="countService.tasksCount$ | async"
              [matBadgeHidden]="!(countService.tasksCount$ | async)"
              >list</mat-icon
            >
            <a matLine>My Tasks</a>
          </mat-list-item>
          <mat-list-item
            (click)="listItemClick()"
            routerLink="/quotes"
            *ngIf="useQuotes"
          >
            <mat-icon
              matListIcon
              [matBadge]="countService.quotesCount$ | async"
              [matBadgeHidden]="!(countService.quotesCount$ | async)"
              >thumbs_up_down</mat-icon
            >
            <a matLine>My Quotes</a>
          </mat-list-item>
          <mat-list-item (click)="listItemClick()" routerLink="/cases">
            <mat-icon matListIcon>folder_shared</mat-icon>
            <a matLine>My Cases</a>
          </mat-list-item>
          <div fxFlex></div>
          <mat-list-item (click)="listItemClick()" routerLink="/settings">
            <mat-icon matListIcon>settings</mat-icon>
            <span matLine>Settings</span>
          </mat-list-item>
          <mat-list-item
            (click)="listItemClick()"
            routerLink="/privacy"
            *ngIf="usePrivacySettings"
          >
            <mat-icon matListIcon>vpn_key</mat-icon>
            <span matLine>Privacy</span>
          </mat-list-item>
          <mat-list-item (click)="signout($event)" class="signout-item">
            <mat-icon matListIcon>exit_to_app</mat-icon>
            <span matLine>Logout</span>
          </mat-list-item>
        </mat-nav-list>
      </div>
      <mat-divider vertical></mat-divider>
    </div>
  </mat-sidenav>

  <mat-sidenav #notificationSidenav position="end" class="notification-sidenav">
    <lfs-notifications></lfs-notifications>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
