import { Component, OnInit } from '@angular/core';
import { faFrown } from '@fortawesome/free-regular-svg-icons';
import { BaseErrorComponent } from '../error-base/error-base.component';

@Component({
    selector: 'lfs-app-error',
    templateUrl: '../error-base/error-base.component.html',
    styleUrls: ['../error-base/error-base.component.scss']
})

export class AppErrorComponent extends BaseErrorComponent implements OnInit {
    constructor() { super(); }

    ngOnInit() {
        this.icon = faFrown;

        switch (this.status) {
            case 1000:
                this.title = 'Unknown Instruction';
                this.message = 'The site received an instruction from the server that it did not understand';
                this.helpItems = [
                    `You may have a cached version of the site that is out of date. Try clearing your browser's
                    temporary files and then <a href="/">return to the home page</a>`
                ];
                break;
            case 1001:
                this.title = 'Oops we encountered a problem';
                this.message = 'We were unable to validate your login';
                this.helpItems = [
                    `<ol>
                        <li class="help-item">If this is the first time you have seen this message, try
                        <a href="/">clicking here</a> to refresh the site.<br/><br/></li>
                        <li>If accessing the site via a bookmark please ensure that
                        this is set to ${window.document.location.origin}<br/><br/></li>
                        <li>Please check the time on your device's clock it must be accurate to within five minutes (fast or slow.)</li>
                    </ol>`
                ];
                break;
            default:
                this.title = 'Client Error';
                break;
        }
    }
}
