import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ActivityStep, ActivityStepResult } from '../../../../_state/models/activity-step.model';


@Injectable({ providedIn: 'root' })
export class ActivityStepService {
    constructor(private http: HttpClient) { }

    private readonly activityStepUrl = `${environment.presentation.url}/api/v2/ActivityStep`;

    saveActivityStepResult(activityStepId: string, activityStepResult: ActivityStepResult) {
        return this.http.patch<ActivityStep>(`${this.activityStepUrl}/${activityStepId}/Result/Update`, activityStepResult);
    }

    submitActivityStepResult(activityStepId: string, activityStepResult: ActivityStepResult) {
        return this.http.patch(`${this.activityStepUrl}/${activityStepId}/Result/Submit`, activityStepResult, { headers: { 'Content-Type': 'application/json' } });
    }

    markStepAsInProgress(activityStepId: string) {
        return this.http.patch<ActivityStep>(`${this.activityStepUrl}/${activityStepId}/Status/InProgress`, {});
    }
}
