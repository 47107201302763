import { Component, OnInit } from '@angular/core';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { BaseErrorComponent } from '../error-base/error-base.component';

@Component({
    selector: 'lfs-error-503',
    templateUrl: '../error-base/error-base.component.html',
    styleUrls: ['../error-base/error-base.component.scss']
})
export class Error503Component extends BaseErrorComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        this.icon = faWrench;
        this.message = `Unfortunately, we're unable to process your request at this time as our servers
                        are currently undergoing maintenance. Please
                        try again later`;
        this.title = 'Service Unavailable';
    }
}
