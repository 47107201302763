import { environment } from '../../../environments/environment';
import { AuthenticationService } from './authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
export class AuthenticationInterceptor {
    constructor(authenticationService) {
        this.authenticationService = authenticationService;
        this.apiUrls = [environment.presentation.url, environment.document.url];
    }
    isApiUrl(url, apiUrls) {
        return apiUrls.some(apiUrl => url.startsWith(apiUrl));
    }
    intercept(req, next) {
        var accessToken = this.authenticationService.accessToken;
        if (accessToken && this.isApiUrl(req.url, this.apiUrls) && !req.headers.has('Authorization')) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
        }
        return next.handle(req);
    }
}
AuthenticationInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationInterceptor_Factory() { return new AuthenticationInterceptor(i0.ɵɵinject(i1.AuthenticationService)); }, token: AuthenticationInterceptor, providedIn: "root" });
