import { createAction, props } from '@ngrx/store';
import { ErrorData } from '../models/error.model';

export const error = createAction(
    '[Error] - Error',
    props<{
        logError: boolean;
        error: ErrorData;
    }>()
);

export const globalError = createAction(
    '[Error] - Global Error',
    props<{
        logError: boolean;
        error: ErrorData;
    }>()
);
