/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/material/list";
import * as i5 from "@angular/material/core";
import * as i6 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i11 from "@angular/common";
import * as i12 from "@angular/flex-layout/flex";
import * as i13 from "@angular/flex-layout/core";
import * as i14 from "../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i15 from "@angular/material/toolbar";
import * as i16 from "@angular/cdk/platform";
import * as i17 from "./notifications.component";
import * as i18 from "@ngrx/store";
import * as i19 from "../notification.service";
import * as i20 from "@angular/router";
import * as i21 from "../../../_features/authentication/authentication.service";
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
function View_NotificationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-icon", [["class", "mat-icon notranslate mat-list-icon"], ["mat-list-icon", ""], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), i1.ɵdid(2, 16384, [[4, 4]], 0, i4.MatListIconCssMatStyler, [], null, null), (_l()(), i1.ɵted(-1, 0, ["fiber_new"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_NotificationsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "mat-caption mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, [[2, 4]], 0, i5.MatLine, [], null, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.caption; _ck(_v, 2, 0, currVal_0); }); }
function View_NotificationsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "mat-caption mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, [[2, 4]], 0, i5.MatLine, [], null, null), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.context.$implicit.timestamp, "short")); _ck(_v, 2, 0, currVal_0); }); }
function View_NotificationsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNotificationClick(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.action.label; _ck(_v, 2, 0, currVal_2); }); }
function View_NotificationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.markAsRead(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatListItem_0, i10.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i4.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i4.MatNavList], [2, i4.MatList]], null, null), i1.ɵqud(603979776, 2, { _lines: 1 }), i1.ɵqud(603979776, 3, { _avatar: 0 }), i1.ɵqud(603979776, 4, { _icon: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NotificationsComponent_4)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 1, 2, "div", [["class", "mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, [[2, 4]], 0, i5.MatLine, [], null, null), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_NotificationsComponent_5)), i1.ɵdid(11, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_NotificationsComponent_6)), i1.ɵdid(13, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_NotificationsComponent_7)), i1.ɵdid(15, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, 2, 4, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeNotification(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(18, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(19, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { var currVal_2 = !_v.context.$implicit.read; _ck(_v, 6, 0, currVal_2); var currVal_4 = _v.context.$implicit.caption; _ck(_v, 11, 0, currVal_4); var currVal_5 = _v.context.$implicit.timestamp; _ck(_v, 13, 0, currVal_5); var currVal_6 = _v.context.$implicit.action; _ck(_v, 15, 0, currVal_6); _ck(_v, 19, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_3); var currVal_7 = (i1.ɵnov(_v, 17).disabled || null); var currVal_8 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_7, currVal_8); var currVal_9 = i1.ɵnov(_v, 19).inline; var currVal_10 = (((i1.ɵnov(_v, 19).color !== "primary") && (i1.ɵnov(_v, 19).color !== "accent")) && (i1.ɵnov(_v, 19).color !== "warn")); _ck(_v, 18, 0, currVal_9, currVal_10); }); }
function View_NotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_3)), i1.ɵdid(2, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotificationsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i10.View_MatListItem_0, i10.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i4.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i4.MatNavList], [2, i4.MatList]], null, null), i1.ɵqud(603979776, 5, { _lines: 1 }), i1.ɵqud(603979776, 6, { _avatar: 0 }), i1.ɵqud(603979776, 7, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "div", [["class", "mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, [[5, 4]], 0, i5.MatLine, [], null, null), (_l()(), i1.ɵted(-1, null, ["You have no notifications"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_NotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-list", [["class", "mat-list mat-list-base"], ["fxFlex", ""]], null, null, null, i10.View_MatList_0, i10.RenderType_MatList)), i1.ɵdid(1, 671744, null, 0, i12.DefaultFlexDirective, [i1.ElementRef, i13.StyleUtils, i13.LAYOUT_CONFIG, i12.FlexStyleBuilder, i13.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(2, 704512, null, 0, i4.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NotificationsComponent_2)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noNotifications", 2]], 0, 0, null, View_NotificationsComponent_8))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.ngIf.length; var currVal_2 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_NotificationsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i11.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "height-full"], ["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i12.DefaultLayoutDirective, [i1.ElementRef, i13.StyleUtils, [2, i12.LayoutStyleBuilder], i13.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "mat-toolbar", [["class", "mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i14.View_MatToolbar_0, i14.RenderType_MatToolbar)), i1.ɵdid(4, 4243456, null, 1, i15.MatToolbar, [i1.ElementRef, i16.Platform, i11.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notifications"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotificationsComponent_1)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.notifications$)); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 4)._toolbarRows.length > 0); var currVal_2 = (i1.ɵnov(_v, 4)._toolbarRows.length === 0); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lfs-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵdid(1, 49152, null, 0, i17.NotificationsComponent, [i18.Store, i19.NotificationService, i20.Router, i21.AuthenticationService], null, null)], null, null); }
var NotificationsComponentNgFactory = i1.ɵccf("lfs-notifications", i17.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };
