import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorActionProps } from './error-action-props.model';

export function handleError<P extends ErrorActionProps>(failureActionCreator: (props: P) => Action): (source: Observable<Action>) => Observable<Action> {
    return (source: Observable<Action>) => source.pipe(
        catchError((err: HttpErrorResponse) => {
            let errorProps: ErrorActionProps;

            if (err.status === 0 || !navigator.onLine) {
                errorProps = {
                    error: {
                        status: 0,
                        message: 'No internet connection available.'
                    }
                };
            } else {
                errorProps = {
                    error: {
                        status: err.status,
                        message: err.error instanceof ErrorEvent ? err.error.message : `${err.message} - ${err.error}`
                    }
                };
            }

            return of(failureActionCreator(errorProps as P));
        })
    );
}
