import { Component, OnInit } from '@angular/core';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { BaseErrorComponent } from '../error-base/error-base.component';

@Component({
    selector: 'lfs-error-404',
    templateUrl: '../error-base/error-base.component.html',
    styleUrls: ['../error-base/error-base.component.scss']
})

export class Error404Component extends BaseErrorComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        this.icon = faQuestion;
        this.title = 'Not Found';
        this.message = 'The information you have requested cannot be found. It is possible that it has been moved or removed.';
        this.helpItems = [
            'If you have copied this link from somewhere check that it has copied correctly.',
            'If you have arrived here from a bookmark please go to the <a href="/">home page</a> and update your bookmark.'
        ];
    }
}
