import { Component, OnInit } from '@angular/core';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { BaseErrorComponent } from '../error-base/error-base.component';

@Component({
    selector: 'lfs-error-403',
    templateUrl: '../error-base/error-base.component.html',
    styleUrls: ['../error-base/error-base.component.scss']
})

export class Error403Component extends BaseErrorComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        this.icon = faUserLock;
        this.title = 'Access Denied';
        this.message = 'You do not have permission to write to access the information you have requested.';
        this.helpItems = [
            'If you believe that you should have access to the information please contact your solicitor',
            'You can return to your home page by <a href="/">clicking here</a>'
        ];
    }
}
