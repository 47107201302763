import { ActivityStep, ActivityStepResult } from "./activity-step.model";

export interface QuoteActivityStep extends ActivityStep {
  result: QuoteActivityStepResult;
  metadata: QuoteActivityStepMetadata;
}

export interface QuoteActivityStepResult extends ActivityStepResult {
  values: {
    quote: QuoteResult;
  };
}

export enum QuoteResult {
  Accepted = "accepted",
  Declined = "declined",
}

export interface QuoteActivityStepMetadata {
  id: string;
  messages: {
    introduction: string;
    accepted: string;
    postAccepted: string;
    postAcceptedNotLoggedIn: string;
    expired: string;
    declinedButNotExpired: string;
    declined: string;
    postDeclined: string;
    postDeclinedNotLoggedIn: string;
    preDecline: string;
  };
  requireQuoteDeclineReason: boolean;
  quoteDeclineReasons: QuoteDeclineReason[];
  QuoteMetadata: QuoteMetadata;
  AcaboomPresentation: AcaboomPresentation;
}

export interface QuoteDeclineReason {
  key: string;
  value: string;
}

export interface QuoteMetadata {
  quoteProcessorId: number;
  processorData: ProcessorData;
  notificationStepIds: string[];
  stepId: string;
  templateProcessorOptionId: number;
}

export interface ProcessorData {
  createPresentationDto: CreatePresentationDto;
}

export interface CreatePresentationDto {
  propertyId: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  workNumber: any;
  homeNumber: any;
  mobileNumber: string;
  secondTitle: any;
  secondFirstName: any;
  secondLastName: any;
  secondEmail: any;
  secondWorkNumber: any;
  secondHomeNumber: any;
  secondMobileNumber: any;
  addressLine1: string;
  addressLine2: string;
  town: string;
  postcode: string;
  rental: any;
  valuationDateTime: string;
  agentId: number;
  valuerId: number;
  templateId: number;
  sendPreVal: any;
  sendCopyToValuer: any;
  sendSms: any;
  quote: string;
  url: string;
}

export interface AcaboomPresentation {
  presId: number;
  presLink: string;
  firstVendorLink: string;
}
