import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { NotificationService } from "../../common/notifications/notification.service";
import * as NotificationActions from "../actions/notifications.actions";

@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private notificationsService: NotificationService
  ) {}

  addNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.addNotification),
      tap(({ notification }) => {
        this.notificationsService.notify(notification);
      })
    ),
    { dispatch: false }
  );
}
