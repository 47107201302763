import { ActivityStepStatus } from "./activity-step-status.model";

export enum ActivityStepType {
  BankCheck = "BankCheck",
  Email = "Email",
  ESignature = "ESignature",
  Form = "Form",
  IdCheck = "IdCheck",
  MarketingPreferencesForm = "MarketingPreferencesForm",
  OfficialCopy = "OfficialCopy",
  Payment = "Payment",
  Quote = "Quote",
  SMS = "SMS",
  SofCheck = "SofCheck",
}

export interface ActivityStep {
  id: string;
  correlationId: string;
  type: ActivityStepType;
  activityId: string;
  batchId: string;
  metadata: ActivityStepMetadata;
  result: ActivityStepResult;
  assigneeId: string;
  ownerId: string;
  description: string;
  status: string;
  statusId: ActivityStepStatus;
  created: string;
  updated: string;
  resultUpdated: string;
  expires: string;
  due: string;
  submitted: string;
  history: ActivityStepHistory[];
  createdBy: string;
  updatedBy: string;
  dependentOn: ActivityStepWorkflowRule[];
  filters: ActivityFilter[];
  allowResubmission: boolean;
  localStorageKey: string;
  maxTotalDocuments: number;
  maxTotalDocumentSize: number;
  isDeleted: boolean;
  reminders: ActivityStepReminder[];
  lastReminderSentOn: Date;
  nextReminderDueOn: Date;
}

export interface ActivityStepMetadata {
  [key: string]: any;
}

export type ActivityStepResultValues = { [key: string]: any };

export interface ActivityStepResult {
  acknowledgements: { [key: string]: boolean };
  values: ActivityStepResultValues;
  currentPage: number;
  visitedPages: number[];
  isAutoSave: boolean;
}

export interface ActivityStepHistory {
  id: number;
  type: string;
  result: ActivityStepResult;
  activityStepId: string;
  date: Date;
  userId: string;
  statusId: number;
  isAutoSave: boolean;
}

export interface ActivityStepWorkflowRule {
  id: number;
  dependentStepId: string;
  controlStepId: string;
  type: ActivityStepWorkflowRuleType;
  status: ActivityStepWorkflowRuleStatus;
  expectedStatusId: number;
  date: Date;
  dateOffset: number;
  expectedValues: ExpectedValue[];
  creationMetadata: StepCreationMetadata;
}

export enum ActivityStepWorkflowRuleType {
  Status = 0,
  NotStatus = 1,
  Value = 2,
  ActiveDate = 3,
  SetDueDate = 4,
  SetExpiryDate = 5,
  PullResultOnCreate = 6,
  RequiresResult = 7,
  CreateTask = 8,
}

export enum ActivityStepWorkflowRuleStatus {
  Pending = 0,
  ToBeProcessed = 1,
  Processed = 2,
  Faulted = 3,
}

export interface ExpectedValue {
  id: number;
  key: string;
  value: string;
}

export interface StepCreationMetadata {
  copyFiltersFromParentStep: string;
  typeHint: string;
  idOverride: string;
  reminderIntervals: string;
}

export interface ActivityFilter {
  key: string;
  value: string;
}

export interface ActivityStepReminder {
  daysAfterCreation: number;
}
