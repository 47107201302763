import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProfileService {
    constructor(http) {
        this.http = http;
        this.url = `${environment.presentation.url}/api/v1/Me`;
    }
    loadProfile() {
        return this.http.get(this.url);
    }
}
ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.HttpClient)); }, token: ProfileService, providedIn: "root" });
