import { Component, OnInit } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { BaseErrorComponent } from '../error-base/error-base.component';

@Component({
    selector: 'lfs-error-5xx',
    templateUrl: '../error-base/error-base.component.html',
    styleUrls: ['../error-base/error-base.component.scss']
})

export class Error5XXComponent extends BaseErrorComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        this.icon = faTimesCircle;
        this.title = 'Server Error';
        this.message = 'The server encountered an error while processing your request.';
        this.iconClass = 'error';
    }
}
