import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, of } from "rxjs";
import { concatMap, map, mergeMap } from "rxjs/operators";
import { AuthenticationService } from "../../_features/authentication/authentication.service";
import { handleError } from "../../_features/error/handle-effect-error.decorator";
import { ActivityStepService } from "../../_features/me/components/steps/activity-step.service";
import { ActivityService } from "../../_features/me/components/steps/activity.service";
import * as ActivityActions from "../actions/activity.actions";
import * as NotificationsActions from "../actions/notifications.actions";
import * as RouterActions from "../actions/router.actions";
import { ActivityStepStatus } from "../models/activity-step-status.model";
import { ActivityStep, ActivityStepType } from "../models/activity-step.model";
import { FormActivityStep } from "../models/form-activity-step.model";
import { IdCheckActivityStep } from "../models/id-check-activity-step.model";
import { Notification, RouteNavigation } from "../models/notification.model";
import {
    PaymentActivityStep,
    PaymentProcessor,
} from "../models/payment-activity-step.model";
import { QuoteActivityStep } from "../models/quote-activity-step.model";

@Injectable()
export class ActivityEffects {
    constructor(
        private actions$: Actions,
        private activityService: ActivityService,
        private activityStepService: ActivityStepService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    loadActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.loadActivities),
            mergeMap(() =>
                this.activityService.getActivities().pipe(
                    map((activities) =>
                        ActivityActions.loadActivitiesSuccess({ activities })
                    ),
                    handleError(ActivityActions.loadActivitiesFailed)
                )
            )
        )
    );

    addActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.addActivities),
            mergeMap(({ activities }) => {
                let activitySteps: ActivityStep[] = activities.reduce(
                    (acc, activity) => acc.concat(activity.activitySteps),
                    []
                );

                // Filter out deleted steps
                activitySteps = activitySteps.filter(
                    (activityStep) => activityStep.isDeleted !== true
                );

                // Filter by status
                activitySteps = activitySteps.filter((activityStep) =>
                    [
                        ActivityStepStatus.NotSet,
                        ActivityStepStatus.ReadyToExecute,
                        ActivityStepStatus.InProgress,
                    ].includes(activityStep.statusId)
                );

                // Filter by type if applicable
                activitySteps = activitySteps.filter((activityStep) =>
                    [
                        ActivityStepType.Form,
                        ActivityStepType.MarketingPreferencesForm,
                        ActivityStepType.IdCheck,
                        ActivityStepType.Payment,
                        ActivityStepType.Quote,
                    ].includes(activityStep.type)
                );

                // Filter out expired steps
                const now = new Date();
                activitySteps = activitySteps.filter((activityStep) => {
                    return !activityStep.expires || new Date(activityStep.expires) > now;
                    // Include the step if expires is null or the expiration date is in the future
                });

                return activitySteps.map((activityStep) => {
                    const notification = new Notification(
                        `New Task`,
                        activityStep.description
                    );

                    const route = this.generateActivityStepRouteNavigation(activityStep);
                    if (route) {
                        notification.action = {
                            label: "VIEW",
                            route,
                        };
                    }

                    return NotificationsActions.addNotification({ notification, userId: this.authenticationService.sub });
                });
            })
        )
    );

    addActivityStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.addActivityStep),
            map(({ activityStep }) => {
                const notification = new Notification(
                    `New Task`,
                    activityStep.description
                );

                const route = this.generateActivityStepRouteNavigation(activityStep);
                if (route) {
                    notification.action = {
                        label: "VIEW",
                        route,
                    };
                }

                return NotificationsActions.addNotification({ notification, userId: this.authenticationService.sub });
            })
        )
    );

    updateActivityStepResult$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.updateActivityStepResult),
            mergeMap(({ activityStep, activityStepResult }) =>
                this.activityStepService
                    .saveActivityStepResult(activityStep.id, activityStepResult)
                    .pipe(
                        map(() =>
                            ActivityActions.updateActivityStepResultSuccess({
                                activityStep,
                                activityStepResult,
                            })
                        ),
                        handleError(ActivityActions.updateActivityStepResultFailed)
                    )
            )
        )
    );

    patchActivityStepResult$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.patchActivityStepResult),
            mergeMap(({ activityStep, activityStepResult }) =>
                this.activityStepService
                    .saveActivityStepResult(activityStep.id, {
                        ...activityStepResult,
                        isAutoSave: true,
                    })
                    .pipe(
                        map(() =>
                            ActivityActions.patchActivityStepResultSuccess({
                                activityStep,
                                activityStepResult,
                            })
                        ),
                        handleError(ActivityActions.patchActivityStepResultFailed)
                    )
            )
        )
    );

    updateActivityStepResultValue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.updateActivityStepResultValue),
            map(({ activityStep, key, value }) => {
                const activityStepResult = {
                    ...activityStep.result,
                    values: {
                        ...activityStep.result.values,
                        [key]: value,
                    },
                };

                return ActivityActions.updateActivityStepResult({
                    activityStep,
                    activityStepResult,
                });
            })
        )
    );

    submitActivityStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.submitActivityStep),
            mergeMap(({ activityStep, activityStepResult }) =>
                this.activityStepService
                    .submitActivityStepResult(activityStep.id, activityStepResult)
                    .pipe(
                        map(() =>
                            ActivityActions.submitActivityStepSuccess({ activityStep })
                        ),
                        handleError(ActivityActions.submitActivityStepFailed)
                    )
            )
        )
    );

    submitActivityStepSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.submitActivityStepSuccess),
            mergeMap(({ activityStep }) => {
                const notification = new Notification(
                    `Task Submitted`,
                    activityStep.description
                );

                const route = this.generateActivityStepRouteNavigation(activityStep);
                if (route) {
                    notification.action = {
                        label: "VIEW",
                        route,
                    };
                }

                return [
                    RouterActions.go({ commands: ["/"] }),
                    NotificationsActions.addNotification({ notification, userId: this.authenticationService.sub }),
                ];
            })
        )
    );

    selectActivityStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.selectActivityStep),
            concatMap(({ activityStep }) => {
                switch (activityStep.type) {
                    case ActivityStepType.Form:
                    case ActivityStepType.MarketingPreferencesForm: {
                        const formActivityStep = activityStep as FormActivityStep;
                        if (formActivityStep.metadata.id) {
                            this.router.navigate([
                                "activity",
                                formActivityStep.id,
                                "form",
                                formActivityStep.metadata.id,
                            ]);
                        } else {
                            this.router.navigate(["activity", formActivityStep.id, "form"]);
                        }
                        break;
                    }
                    case ActivityStepType.Quote: {
                        const quoteActivityStep = activityStep as QuoteActivityStep;
                        this.router.navigate(
                            [
                                "activity",
                                quoteActivityStep.id,
                                "quote",
                                quoteActivityStep.metadata.id,
                            ],
                            { queryParamsHandling: "preserve" }
                        );
                        break;
                    }
                    case ActivityStepType.Payment: {
                        const paymentActivityStep = activityStep as PaymentActivityStep;
                        switch (
                        paymentActivityStep.metadata.paymentRequest.paymentProcessor
                        ) {
                            case PaymentProcessor.PAY360: {
                                this.router.navigate([
                                    "activity",
                                    paymentActivityStep.id,
                                    "payment",
                                    "pay360",
                                ]);
                                break;
                            }
                            case PaymentProcessor.STRIPE: {
                                this.router.navigate([
                                    "activity",
                                    paymentActivityStep.id,
                                    "payment",
                                    "stripe",
                                ]);
                                break;
                            }
                        }
                        break;
                    }
                    case ActivityStepType.IdCheck: {
                        const idCheckActivityStep = activityStep as IdCheckActivityStep;
                        this.router.navigate([
                            "activity",
                            idCheckActivityStep.id,
                            "idcheck",
                        ]);
                        break;
                    }
                    default: {
                        this.router.navigate(["activity", activityStep.id]);
                        break;
                    }
                }

                if (
                    activityStep.statusId === ActivityStepStatus.NotSet ||
                    activityStep.statusId === ActivityStepStatus.ReadyToExecute
                ) {
                    return of(ActivityActions.markStepAsInProgress({ activityStep }));
                } else {
                    return EMPTY;
                }
            })
        )
    );

    markStepAsInProgress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.markStepAsInProgress),
            mergeMap(({ activityStep }) =>
                this.activityStepService.markStepAsInProgress(activityStep.id).pipe(
                    map(() =>
                        ActivityActions.markStepAsInProgressSuccess({ activityStep })
                    ),
                    handleError(ActivityActions.markStepAsInProgressFailed)
                )
            )
        )
    );

    private generateActivityStepRouteNavigation(
        activityStep: ActivityStep
    ): RouteNavigation {
        switch (activityStep.type) {
            case ActivityStepType.Form:
            case ActivityStepType.MarketingPreferencesForm: {
                const formActivityStep = activityStep as FormActivityStep;
                if (formActivityStep.metadata.id) {
                    return new RouteNavigation([
                        "activity",
                        formActivityStep.id,
                        "form",
                        formActivityStep.metadata.id,
                    ]);
                } else {
                    return new RouteNavigation(["activity", formActivityStep.id, "form"]);
                }
            }
            case ActivityStepType.Quote: {
                const quoteActivityStep = activityStep as QuoteActivityStep;
                return new RouteNavigation(
                    [
                        "activity",
                        quoteActivityStep.id,
                        "quote",
                        quoteActivityStep.metadata.id,
                    ],
                    { queryParamsHandling: "preserve" }
                );
            }
            case ActivityStepType.Payment: {
                const paymentActivityStep = activityStep as PaymentActivityStep;
                switch (paymentActivityStep.metadata.paymentRequest.paymentProcessor) {
                    case PaymentProcessor.PAY360: {
                        return new RouteNavigation([
                            "activity",
                            paymentActivityStep.id,
                            "payment",
                            "pay360",
                        ]);
                    }
                    case PaymentProcessor.STRIPE: {
                        return new RouteNavigation([
                            "activity",
                            paymentActivityStep.id,
                            "payment",
                            "stripe",
                        ]);
                    }
                }
                break;
            }
            case ActivityStepType.IdCheck: {
                const idCheckActivityStep = activityStep as IdCheckActivityStep;
                return new RouteNavigation([
                    "activity",
                    idCheckActivityStep.id,
                    "idcheck",
                ]);
            }
            default: {
                return new RouteNavigation(["activity", activityStep.id]);
            }
        }
    }
}
