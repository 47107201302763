/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../error-code/503.component.ngfactory";
import * as i2 from "../error-code/503.component";
import * as i3 from "../error-code/5xx.component.ngfactory";
import * as i4 from "../error-code/5xx.component";
import * as i5 from "@angular/common";
import * as i6 from "../error-code/404.component.ngfactory";
import * as i7 from "../error-code/404.component";
import * as i8 from "../error-code/403.component.ngfactory";
import * as i9 from "../error-code/403.component";
import * as i10 from "../error-code/400.component.ngfactory";
import * as i11 from "../error-code/400.component";
import * as i12 from "../error-code/4xx.component.ngfactory";
import * as i13 from "../error-code/4xx.component";
import * as i14 from "../app-error/app-error.component.ngfactory";
import * as i15 from "../app-error/app-error.component";
import * as i16 from "./error.component";
import * as i17 from "@angular/router";
var styles_ErrorComponent = ["[_nghost-%COMP%] { display: block; width: 100%; }"];
var RenderType_ErrorComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
function View_ErrorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lfs-error-503", [], null, null, null, i1.View_Error503Component_0, i1.RenderType_Error503Component)), i0.ɵdid(1, 114688, null, 0, i2.Error503Component, [], { status: [0, "status"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ErrorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lfs-error-5xx", [], null, null, null, i3.View_Error5XXComponent_0, i3.RenderType_Error5XXComponent)), i0.ɵdid(1, 114688, null, 0, i4.Error5XXComponent, [], { status: [0, "status"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ErrorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_4)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statusCodes.isServerErrorStatusCode(_v.parent.context.ngIf); var currVal_1 = i0.ɵnov(_v.parent, 4); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ErrorComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lfs-error-404", [], null, null, null, i6.View_Error404Component_0, i6.RenderType_Error404Component)), i0.ɵdid(1, 114688, null, 0, i7.Error404Component, [], { status: [0, "status"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ErrorComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_6)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.ngIf === _co.statusCodes.NOT_FOUND); var currVal_1 = i0.ɵnov(_v.parent, 5); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ErrorComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lfs-error-403", [], null, null, null, i8.View_Error403Component_0, i8.RenderType_Error403Component)), i0.ɵdid(1, 114688, null, 0, i9.Error403Component, [], { status: [0, "status"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ErrorComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_8)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.ngIf === _co.statusCodes.FORBIDDEN); var currVal_1 = i0.ɵnov(_v.parent, 6); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ErrorComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lfs-error-400", [], null, null, null, i10.View_Error400Component_0, i10.RenderType_Error400Component)), i0.ɵdid(1, 114688, null, 0, i11.Error400Component, [], { status: [0, "status"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ErrorComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_10)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.ngIf === _co.statusCodes.BAD_REQUEST); var currVal_1 = i0.ɵnov(_v.parent, 7); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ErrorComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lfs-error-4xx", [], null, null, null, i12.View_Error4XXComponent_0, i12.RenderType_Error4XXComponent)), i0.ɵdid(1, 114688, null, 0, i13.Error4XXComponent, [], { status: [0, "status"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ErrorComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_12)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statusCodes.isClientErrorStatusCode(_v.parent.context.ngIf); var currVal_1 = i0.ɵnov(_v.parent, 8); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ErrorComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lfs-app-error", [], null, null, null, i14.View_AppErrorComponent_0, i14.RenderType_AppErrorComponent)), i0.ɵdid(1, 114688, null, 0, i15.AppErrorComponent, [], { status: [0, "status"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ErrorComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_14)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.parent.context.ngIf >= 1000) && (_v.parent.context.ngIf <= 9999)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ErrorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_2)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["other5XXErrors", 2]], null, 0, null, View_ErrorComponent_3)), (_l()(), i0.ɵand(0, [["checkClientErrors", 2]], null, 0, null, View_ErrorComponent_5)), (_l()(), i0.ɵand(0, [["check403Error", 2]], null, 0, null, View_ErrorComponent_7)), (_l()(), i0.ɵand(0, [["check400Error", 2]], null, 0, null, View_ErrorComponent_9)), (_l()(), i0.ɵand(0, [["other4XXErrors", 2]], null, 0, null, View_ErrorComponent_11)), (_l()(), i0.ɵand(0, [["checkAppErrorCodes", 2]], null, 0, null, View_ErrorComponent_13))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.ngIf === _co.statusCodes.SERVICE_UNAVAILABLE); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ErrorComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.status$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lfs-global-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i0.ɵdid(1, 49152, null, 0, i16.ErrorComponent, [i17.ActivatedRoute], null, null)], null, null); }
var ErrorComponentNgFactory = i0.ɵccf("lfs-global-error", i16.ErrorComponent, View_ErrorComponent_Host_0, {}, {}, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
