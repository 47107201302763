import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as ProfileActions from '../actions/profile.actions';
import { Profile } from '../models/profile.model';

export const featureKey = 'profile';

export interface State {
    profile: Profile;
    loading: boolean;
    loaded: boolean;
}

const initialState: State = {
    profile: null,
    loading: false,
    loaded: false
};

const profileReducer = createReducer(
    initialState,
    on(ProfileActions.loadProfile, (state) => ({
        ...state,
        loading: true
    })),
    on(ProfileActions.loadProfileSuccess, (state, { profile }) => ({
        ...state,
        profile,
        loading: false,
        loaded: true
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return profileReducer(state, action);
}

const feature = createFeatureSelector(featureKey);

export const selectProfile = () => createSelector(feature, (s: State) => s.profile);
export const selectLoading = () => createSelector(feature, (s: State) => s.loading);
export const selectLoaded = () => createSelector(feature, (s: State) => s.loaded);
