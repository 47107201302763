import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import HttpStatusCodes from '../../../common/utils/http-status-codes';

@Component({
    selector: 'lfs-global-error',
    templateUrl: './error.component.html',
    styles: [':host { display: block; width: 100%; }']
})
export class ErrorComponent {
    constructor(private route: ActivatedRoute) { }

    status$ = this.route.paramMap.pipe(map((paramMap) => +paramMap.get('status')));
    statusCodes = HttpStatusCodes;
}
