export const environment = {
    production: true,
    discovery: {
        url: "#{LFSPortalUI-DiscoveryUrl}#"
    },
    presentation: {
        url: "#{LFSPortalUI-APIBaseAddress}#"
    },
    document: {
        url: "#{LFSPortalUI-DocumentBaseAddress}#"
    },
    webhook: {
        url: "#{LFSPortalUI-WebhookBaseAddress}#"
    },

    postCodeAnywhereKey: "#{LFSPortalUI-PCAKey}#",
    scopes: "openid profile offline_access step.mine form.fetch me.read activity.reader step.reader document.generate matter.reader milestoneplan.reader",

    clientId: null,
    statusCode: null,
    stsUri: null,
    title: null,
    useQuotes: null,
    useMilestones: null,
    usePrivacySettings: null,
    supportedCommunicationPreference: null
};
