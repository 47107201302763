<ng-container *ngIf="status$ | async as status">
    <lfs-error-503 *ngIf="status === statusCodes.SERVICE_UNAVAILABLE; else other5XXErrors"
        [status]="status"></lfs-error-503>
    <ng-template #other5XXErrors>
        <lfs-error-5xx *ngIf="statusCodes.isServerErrorStatusCode(status); else checkClientErrors;"
            [status]="status"></lfs-error-5xx>
    </ng-template>
    <ng-template #checkClientErrors>
        <lfs-error-404 *ngIf="status === statusCodes.NOT_FOUND; else check403Error" [status]="status"></lfs-error-404>
    </ng-template>
    <ng-template #check403Error>
        <lfs-error-403 *ngIf="status === statusCodes.FORBIDDEN; else check400Error" [status]="status"></lfs-error-403>
    </ng-template>
    <ng-template #check400Error>
        <lfs-error-400 *ngIf="status === statusCodes.BAD_REQUEST; else other4XXErrors"
            [status]="status"></lfs-error-400>
    </ng-template>
    <ng-template #other4XXErrors>
        <lfs-error-4xx *ngIf="statusCodes.isClientErrorStatusCode(status); else checkAppErrorCodes;"
            [status]="status"></lfs-error-4xx>
    </ng-template>
    <ng-template #checkAppErrorCodes>
        <lfs-app-error *ngIf="status >= 1000 && status <=9999" [status]="status"></lfs-app-error>
    </ng-template>
</ng-container>
