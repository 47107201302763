import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../environments/environment";
import { AuthenticationService } from "./_features/authentication/authentication.service";
import { CountService } from "./_features/me/services/count.service";
import * as fromNotifications from "./_state/reducers/notifications.reducer";
import * as fromRoot from "./_state/reducers/root.reducer";
import { NotificationService } from "./common/notifications/notification.service";
import { NavigationService } from "./routing/navigation.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
    title = environment.title;
    useQuotes = environment.useQuotes;
    usePrivacySettings = environment.usePrivacySettings;
    showAccountPanel = true;
    hostname = window.location.hostname;

    notificationsCount$ = this.store.select(fromNotifications.selectUnreadTotal);
    profile$ = this.authenticationService.loadUserProfile();

    isLargeBreakpoint$: Observable<boolean> = this.breakpointObserver
        .observe([Breakpoints.Large, Breakpoints.XLarge])
        .pipe(map((result) => result.matches));

    @ViewChild("sidenav", { static: false }) sidenav: MatSidenav;
    @ViewChild("notificationSidenav", { static: true })
    notificationSidenav: MatSidenav;

    isTemporary = () => this.authenticationService.isTemporary;

    constructor(
        public store: Store<fromRoot.State>,
        public countService: CountService,
        private titleService: Title,
        private breakpointObserver: BreakpointObserver,
        private authenticationService: AuthenticationService,
        private notificationService: NotificationService,
        _navigationService: NavigationService
    ) { }

    ngOnInit() {
        this.titleService.setTitle(environment.title);
    }

    ngAfterViewInit() {
        this.notificationService.setSidenav(this.notificationSidenav);
    }

    listItemClick() {
        if (this.sidenav.mode === "over") {
            this.sidenav.close();
        }
    }

    signout(event: MouseEvent) {
        event.preventDefault();
        this.authenticationService.logout();
    }

    toggleAccountMenu(visible: boolean) {
        this.showAccountPanel = visible;
    }
}
