import { NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { BehaviorSubject, combineLatest, from, interval, } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as AuthenticationActions from "../../_state/actions/authentication.actions";
import * as i0 from "@angular/core";
import * as i1 from "angular-oauth2-oidc";
import * as i2 from "@ngrx/store";
export class AuthenticationService {
    constructor(oauthService, ngZone, authConfig, store) {
        this.oauthService = oauthService;
        this.ngZone = ngZone;
        this.authConfig = authConfig;
        this.store = store;
        this.isAuthenticatedSubject$ = new BehaviorSubject(false);
        this.isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
        this.isDoneLoadingSubject$ = new BehaviorSubject(false);
        this.isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
        this.temporaryToken = null;
        this.canActivateProtectedRoutes$ = combineLatest([
            this.isAuthenticated$,
            this.isDoneLoading$,
        ]).pipe(map((values) => values.every((b) => b)));
    }
    get isTemporary() {
        return this.temporaryToken !== null;
    }
    initializeTemporaryToken(token) {
        this.temporaryToken = token;
        this.isAuthenticatedSubject$.next(true);
        this.isDoneLoadingSubject$.next(true);
    }
    runInitialLoginSequence() {
        if (location.hash) {
            console.log("Encountered hash fragment, plotting as table...");
            console.table(location.hash
                .slice(1)
                .split("&")
                .map((kvp) => kvp.split("=")));
        }
        return this.oauthService
            .loadDiscoveryDocumentAndLogin()
            .then((hasReceivedTokens) => {
            if (hasReceivedTokens) {
                this.initialiseRefresh();
            }
            if (this.oauthService.hasValidAccessToken()) {
                const userId = this.oauthService.getIdentityClaims()['sub'];
                this.store.dispatch(AuthenticationActions.loadUserSuccess({ userId }));
                this.isAuthenticatedSubject$.next(true);
            }
            else {
                this.login();
            }
            this.isDoneLoadingSubject$.next(true);
            return this.isAuthenticatedSubject$.value;
        });
    }
    initialiseRefresh() {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
        const refreshInterval = this.calculateRefreshInterval();
        this.ngZone.runOutsideAngular(() => {
            this.refreshSubscription = interval(refreshInterval)
                .pipe(switchMap(() => this.refreshToken()), catchError((err) => {
                this.login();
                throw err;
            }))
                .subscribe();
        });
    }
    calculateRefreshInterval() {
        const tokenExpiry = this.getAccessTokenExpiration();
        if (!tokenExpiry) {
            return 0;
        }
        const timeUntilExpiry = tokenExpiry - Date.now();
        const refreshTime = timeUntilExpiry * this.authConfig.timeoutFactor;
        return Math.max(refreshTime, 0);
    }
    refreshToken() {
        return this.oauthService.refreshToken().catch((err) => {
            this.login();
            debugger;
            throw err;
        });
    }
    loadUserProfile() {
        if (this.isTemporary) {
            return Promise.resolve(null);
        }
        else {
            return from(this.oauthService.loadUserProfile());
        }
    }
    login(targetUrl) {
        this.oauthService.initLoginFlow(encodeURIComponent(targetUrl || window.location.pathname));
    }
    logout() {
        this.oauthService.logOut();
    }
    hasValidToken() {
        return this.oauthService.hasValidAccessToken() || !!this.temporaryToken;
    }
    getAccessTokenExpiration() {
        return this.oauthService.getAccessTokenExpiration();
    }
    get accessToken() {
        return this.temporaryToken || this.oauthService.getAccessToken();
    }
    get identityClaims() {
        return this.oauthService.getIdentityClaims();
    }
    get idToken() {
        return this.oauthService.getIdToken();
    }
    get logoutUrl() {
        return this.oauthService.logoutUrl;
    }
    get sub() {
        return this.identityClaims["sub"];
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.AuthConfig), i0.ɵɵinject(i2.Store)); }, token: AuthenticationService, providedIn: "root" });
