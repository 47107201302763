import { createAction, props } from "@ngrx/store";
import { TaskListMode } from "../models/task-list-mode.model";
import { UserIdAction } from "./interfaces/user-id";

export const actionGroup = "[User Settings]";

export const setPreferredTaskListMode = createAction(
  `${actionGroup} Set Preferred Task List Mode`,
  props<UserIdAction & { mode: TaskListMode }>()
);
