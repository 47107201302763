import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as ErrorActions from '../actions/error.actions';
import * as RouterActions from '../actions/router.actions';

@Injectable()
export class ErrorEffects {
    constructor(private actions$: Actions) { }

    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ErrorActions.error),
            tap((action) => console.log('Error: ', action.error.message))
        )
    )

    globalError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ErrorActions.globalError),
            map((action) => RouterActions.go({ commands: ['error', action.error.status] }))
        )
    )
}
