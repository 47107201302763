import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  MatButtonModule,
  MatListModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatToolbarModule,
} from "@angular/material";
import { MatIconModule } from "@angular/material/icon";
import { AlertComponent } from "./alert/alert.component";
import { NotificationComponent } from "./notifications/notification/notification.component";
import { NotificationsMenuComponent } from "./notifications/notifications-menu/notifications-menu.component";
import { NotificationsComponent } from "./notifications/notifications/notifications.component";
import { CountryPipe } from "./pipes/country.pipe";
import { PadLeftPipe } from "./pipes/pad-left.pipe";
import { RouterModule } from "@angular/router";

const PIPES = [PadLeftPipe, CountryPipe];

const COMPONENTS = [
  AlertComponent,
  NotificationsComponent,
  NotificationsMenuComponent,
  NotificationComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressBarModule,
    FlexLayoutModule,
    MatButtonModule,
    MatListModule,
    MatToolbarModule,
    RouterModule,
  ],
  exports: [COMPONENTS, PIPES],
  declarations: [COMPONENTS, PIPES],
  entryComponents: [NotificationComponent],
})
export class LfsCommonModule {}
