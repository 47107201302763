<div class="height-full" fxLayout="column">
  <mat-toolbar>
    <span>Notifications</span>
  </mat-toolbar>
  <mat-list fxFlex *ngIf="notifications$ | async as notifications">
    <ng-container *ngIf="notifications.length; else noNotifications">
      <mat-list-item
        *ngFor="let notification of notifications"
        (mouseenter)="markAsRead(notification)"
      >
        <mat-icon *ngIf="!notification.read" mat-list-icon>fiber_new</mat-icon>

        <div mat-line>{{ notification.title }}</div>
        <p mat-line *ngIf="notification.caption" class="mat-caption">
          {{ notification.caption }}
        </p>
        <p mat-line *ngIf="notification.timestamp" class="mat-caption">
          {{ notification.timestamp | date : "short" }}
        </p>

        <button
          *ngIf="notification.action"
          mat-button
          (click)="onNotificationClick(notification)"
        >
          {{ notification.action.label }}
        </button>
        <button mat-icon-button (click)="removeNotification(notification)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-list-item>
    </ng-container>
    <ng-template #noNotifications>
      <mat-list-item>
        <div mat-line>You have no notifications</div>
      </mat-list-item>
    </ng-template>
  </mat-list>
</div>
