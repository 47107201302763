import { NavigationExtras } from "@angular/router";

export class Notification {
  id: string;
  title: string;
  caption: string;
  action?: NotificationAction;
  duration?: number;
  type?: NotificationType;
  read: boolean = false;
  timestamp: Date = new Date();

  constructor(
    title: string,
    caption?: string,
    action?: NotificationAction,
    type: NotificationType = NotificationType.Info
  ) {
    this.id = Math.random().toString(36).substring(2, 11);
    this.title = title;
    this.action = action;
    this.caption = caption;
    this.type = type;
    this.duration = 5000;
  }
}

export class RouteNavigation {
  commands: any[];
  extras?: NavigationExtras;

  constructor(commands: any[], extras?: NavigationExtras) {
    this.commands = commands;
    this.extras = extras;
  }
}

export interface NotificationAction {
  label: string;
  route: RouteNavigation;
}

export enum NotificationType {
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
}
