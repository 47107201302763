import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as CommunicationPreferencesActions from "../actions/communication-preferences.actions";

export const featureKey = "communication-preferences";

export interface State {
  loading: boolean;
  loaded: boolean;
  submitted: boolean;
  submitting: boolean;
  communicationPreference: number;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  submitted: false,
  submitting: false,
  communicationPreference: null,
};

const communicationPreferencesReducer = createReducer(
  initialState,
  on(CommunicationPreferencesActions.loadCommunicationPreference, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    CommunicationPreferencesActions.loadCommunicationPreferenceSuccess,
    (state, { communicationPreference }) => ({
      ...state,
      communicationPreference,
      loading: false,
      loaded: true,
    })
  ),
  on(
    CommunicationPreferencesActions.loadCommunicationPreferenceError,
    (state) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),
  on(
    CommunicationPreferencesActions.updateCommunicationPreference,
    (state) => ({
      ...state,
      loading: true,
      submitting: true,
      submitted: false,
    })
  ),
  on(
    CommunicationPreferencesActions.updateCommunicationPreferenceError,
    CommunicationPreferencesActions.updateCommunicationPreferenceSuccess,
    (state) => ({
      ...state,
      loading: false,
      submitting: false,
      submitted: true,
    })
  ),
  on(CommunicationPreferencesActions.reset, () => ({ ...initialState }))
);

export function reducer(state: State | undefined, action: Action) {
  return communicationPreferencesReducer(state, action);
}

export const selectNotificationsState = createFeatureSelector<State>(
  featureKey
);

export const selectCommunicationPreference = createSelector(
  selectNotificationsState,
  (state: State) => state.communicationPreference
);

export const selectCommunicationPreferenceLoaded = createSelector(
  selectNotificationsState,
  (state: State) => state.loaded
);

export const selectCommunicationPreferenceLoading = createSelector(
  selectNotificationsState,
  (state: State) => state.loading
);

export const selectCommunicationPreferenceSubmitted = createSelector(
  selectNotificationsState,
  (state: State) => state.submitted
);

export const selectCommunicationPreferenceSubmitting = createSelector(
  selectNotificationsState,
  (state: State) => state.submitting
);
