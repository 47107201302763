import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CommunicationPreferencesService {
    constructor(http) {
        this.http = http;
        this.url = `${environment.presentation.url}/api/v1/Me`;
    }
    loadCommunicationPreference() {
        return this.http
            .get(this.url)
            .pipe(map((profile) => profile.communicationPreference));
    }
    updateCommunicationPreference(communicationPreference) {
        return this.http.post(`${this.url}/UpdateCommunicationPreference`, communicationPreference);
    }
}
CommunicationPreferencesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommunicationPreferencesService_Factory() { return new CommunicationPreferencesService(i0.ɵɵinject(i1.HttpClient)); }, token: CommunicationPreferencesService, providedIn: "root" });
