import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { Profile } from "../../../../_state/models/profile.model";
import { CommunicationPreference } from "./communication-preferences.model";

@Injectable({ providedIn: "root" })
export class CommunicationPreferencesService {
  private readonly url = `${environment.presentation.url}/api/v1/Me`;

  constructor(private http: HttpClient) {}

  loadCommunicationPreference(): Observable<CommunicationPreference> {
    return this.http
      .get<Profile>(this.url)
      .pipe(map((profile) => profile.communicationPreference));
  }

  updateCommunicationPreference(
    communicationPreference: CommunicationPreference
  ): Observable<void> {
    return this.http.post<void>(
      `${this.url}/UpdateCommunicationPreference`,
      communicationPreference
    );
  }
}
