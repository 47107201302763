import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as ActivityActions from "../actions/activity.actions";
import { ActivityStepStatus } from "../models/activity-step-status.model";
import { ActivityStep, ActivityStepType } from "../models/activity-step.model";
import { Activity } from "../models/activity.model";
import {
  QuoteActivityStep,
  QuoteResult,
} from "../models/quote-activity-step.model";

export const featureKey = "activity";

export interface State extends EntityState<Activity> {
  loading: boolean;
  loaded: boolean;

  filters: {
    showExpiredActivitySteps: boolean;
    activityStepTypes: ActivityStepType[];
    activityStepStatuses: ActivityStepStatus[];
  };
}

export const adapter: EntityAdapter<Activity> = createEntityAdapter<Activity>({
  selectId: (activity: Activity) => activity.id,
  sortComparer: (a: Activity, b: Activity) => {
    if (a.created > b.created) {
      return -1;
    } else if (a.created < b.created) {
      return 1;
    } else {
      return 0;
    }
  },
});

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,

  filters: {
    showExpiredActivitySteps: false,
    activityStepTypes: [
      ActivityStepType.Form,
      ActivityStepType.MarketingPreferencesForm,
      ActivityStepType.IdCheck,
      ActivityStepType.Payment,
    ],
    activityStepStatuses: [
      ActivityStepStatus.NotSet,
      ActivityStepStatus.ReadyToExecute,
      ActivityStepStatus.InProgress,
    ],
  },
});

const activityReducer = createReducer(
  initialState,
  on(ActivityActions.loadActivities, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(ActivityActions.loadActivitiesSuccess, (state, action) =>
    adapter.addAll(action.activities, {
      ...state,
      loading: false,
      loaded: true,
    })
  ),
  on(ActivityActions.loadActivitiesFailed, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(ActivityActions.addActivities, (state, { activities }) =>
    adapter.addMany(activities, state)
  ),
  on(
    ActivityActions.addActivityStep,
    ActivityActions.updateActivityStep,
    (state, { activityStep }) => {
      // Retrieve the current activity from the state.
      const activity = state.entities[activityStep.activityId];

      // Check if the activity step already exists.
      const existingStepIndex = activity.activitySteps.findIndex(
        (step) => step.id === activityStep.id
      );

      let updatedActivitySteps: ActivityStep[];
      if (existingStepIndex !== -1) {
        // If the step exists, replace it with the new one.
        updatedActivitySteps = activity.activitySteps.map((step, index) =>
          index === existingStepIndex ? activityStep : step
        );
      } else {
        // If the step does not exist, add it to the array.
        updatedActivitySteps = [...activity.activitySteps, activityStep];
      }

      // Create the updated activity object with the new list of steps.
      const updatedActivity = {
        ...activity,
        activitySteps: updatedActivitySteps,
      };

      // Update the state with the modified activity.
      return adapter.updateOne(
        { id: activity.id, changes: updatedActivity },
        state
      );
    }
  ),
  on(
    ActivityActions.updateActivityStepResult,
    ActivityActions.patchActivityStepResult,
    (state, { activityStep, activityStepResult }) => {
      const activity = state.entities[activityStep.activityId];
      const updatedActivity = {
        ...activity,
        activitySteps: activity.activitySteps.map((step) => {
          if (step.id === activityStep.id) {
            return {
              ...step,
              result: {
                ...step.result,
                ...activityStepResult,
              },
            };
          }

          return step;
        }),
      };
      return adapter.updateOne(
        { id: activity.id, changes: updatedActivity },
        state
      );
    }
  ),
  on(ActivityActions.markStepAsInProgress, (state, { activityStep }) => {
    const activity = state.entities[activityStep.activityId];
    const updatedActivity = {
      ...activity,
      activitySteps: activity.activitySteps.map((step) => {
        if (step.id === activityStep.id) {
          return {
            ...step,
            statusId: ActivityStepStatus.InProgress,
          };
        }

        return step;
      }),
    };
    return adapter.updateOne(
      { id: activity.id, changes: updatedActivity },
      state
    );
  }),
  on(ActivityActions.addActivityStepStatusFilter, (state, { status }) => ({
    ...state,
    filters: {
      ...state.filters,
      activityStepStatuses: [...state.filters.activityStepStatuses, status],
    },
  })),
  on(ActivityActions.removeActivityStepStatusFilter, (state, { status }) => ({
    ...state,
    filters: {
      ...state.filters,
      activityStepStatuses: state.filters.activityStepStatuses.filter(
        (s) => s !== status
      ),
    },
  })),
  on(ActivityActions.addActivityStepStatusesFilter, (state, { statuses }) => ({
    ...state,
    filters: {
      ...state.filters,
      activityStepStatuses: [
        ...state.filters.activityStepStatuses,
        ...statuses,
      ],
    },
  })),
  on(
    ActivityActions.removeActivityStepStatusesFilter,
    (state, { statuses }) => ({
      ...state,
      filters: {
        ...state.filters,
        activityStepStatuses: state.filters.activityStepStatuses.filter(
          (s) => !statuses.includes(s)
        ),
      },
    })
  ),
  on(
    ActivityActions.showExpiredActivitySteps,
    (state, { showExpiredActivitySteps }) => ({
      ...state,
      filters: {
        ...state.filters,
        showExpiredActivitySteps,
      },
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return activityReducer(state, action);
}

const selectActivitiesState = createFeatureSelector<State>(featureKey);

const { selectAll } = adapter.getSelectors(selectActivitiesState);

export const selectActivityStepTypesFilters = createSelector(
  selectActivitiesState,
  (s) => s.filters.activityStepTypes
);
export const selectActivityStepStatusesFilters = createSelector(
  selectActivitiesState,
  (s) => s.filters.activityStepStatuses
);
export const selectShowExpiredActivitySteps = createSelector(
  selectActivitiesState,
  (s) => s.filters.showExpiredActivitySteps
);

export const selectActivitySteps = () =>
  createSelector(
    selectAll,
    selectActivityStepTypesFilters,
    selectActivityStepStatusesFilters,
    (
      activities: Activity[],
      activityStepTypes: ActivityStepType[],
      activityStepStatuses: ActivityStepStatus[]
    ) => {
      let activitySteps: ActivityStep[] = activities.reduce(
        (acc, activity) => acc.concat(activity.activitySteps),
        []
      );

      // Filter out deleted steps
      activitySteps = activitySteps.filter(
        (activityStep) => activityStep.isDeleted !== true
      );

      activitySteps = activitySteps.filter((activityStep: ActivityStep) =>
        activityStepStatuses.includes(activityStep.statusId)
      );

      if (activityStepTypes && activityStepTypes.length) {
        activitySteps = activitySteps.filter((activityStep: ActivityStep) =>
          activityStepTypes.includes(activityStep.type)
        );
      }

      return activitySteps;
    }
  );

export const selectActionableActivitySteps = () =>
  createSelector(
    selectAll,
    selectActivityStepTypesFilters,
    (activities: Activity[], activityStepTypes: ActivityStepType[]) => {
      let activitySteps: ActivityStep[] = activities.reduce(
        (acc, activity) => acc.concat(activity.activitySteps),
        []
      );

      // Filter out deleted steps
      activitySteps = activitySteps.filter(
        (activityStep) => activityStep.isDeleted !== true
      );

      // Filter by status
      activitySteps = activitySteps.filter((activityStep) =>
        [
          ActivityStepStatus.NotSet,
          ActivityStepStatus.ReadyToExecute,
          ActivityStepStatus.InProgress,
        ].includes(activityStep.statusId)
      );

      // Filter by type if applicable
      if (activityStepTypes && activityStepTypes.length) {
        activitySteps = activitySteps.filter((activityStep) =>
          activityStepTypes.includes(activityStep.type)
        );
      }

      // Filter out expired steps
      const now = new Date();
      activitySteps = activitySteps.filter((activityStep) => {
        return !activityStep.expires || new Date(activityStep.expires) > now;
        // Include the step if expires is null or the expiration date is in the future
      });

      return activitySteps;
    }
  );

export const selectActionableActivityStepsByMatterId = (matterId: number) =>
  createSelector(
    selectAll,
    selectActivityStepTypesFilters,
    (activities: Activity[], activityStepTypes: ActivityStepType[]) => {
      let activitySteps: ActivityStep[] = activities
        .filter((activity) => {
          const hasMatterId = activity.filters.some(
            (filter) =>
              filter.key === "lfs:matterid" &&
              filter.value === matterId.toString()
          );
          return hasMatterId;
        })
        .reduce((acc, activity) => [...acc, ...activity.activitySteps], []);

      // Filter out deleted steps
      activitySteps = activitySteps.filter(
        (activityStep) => activityStep.isDeleted !== true
      );

      // Filter by status
      activitySteps = activitySteps.filter((activityStep) =>
        [
          ActivityStepStatus.NotSet,
          ActivityStepStatus.ReadyToExecute,
          ActivityStepStatus.InProgress,
        ].includes(activityStep.statusId)
      );

      // Filter by type if applicable
      if (activityStepTypes && activityStepTypes.length) {
        activitySteps = activitySteps.filter((activityStep) =>
          activityStepTypes.includes(activityStep.type)
        );
      }

      // Filter out expired steps
      const now = new Date();
      activitySteps = activitySteps.filter((activityStep) => {
        return !activityStep.expires || new Date(activityStep.expires) > now;
        // Include the step if expires is null or the expiration date is in the future
      });

      return activitySteps;
    }
  );

export const selectActivityStepsByMatterId = (matterId: number) =>
  createSelector(
    selectAll,
    selectActivityStepTypesFilters,
    selectActivityStepStatusesFilters,
    (
      activities: Activity[],
      activityStepTypes: ActivityStepType[],
      activityStepStatuses: ActivityStepStatus[]
    ) => {
      let activitySteps: ActivityStep[] = activities
        .filter((activity) => {
          const hasMatterId = activity.filters.some(
            (filter) =>
              filter.key === "lfs:matterid" &&
              filter.value === matterId.toString()
          );
          return hasMatterId;
        })
        .reduce((acc, activity) => [...acc, ...activity.activitySteps], []);

      // Filter out deleted steps
      activitySteps = activitySteps.filter(
        (activityStep) => activityStep.isDeleted !== true
      );

      activitySteps = activitySteps.filter((step) =>
        activityStepStatuses.includes(step.statusId)
      );

      if (activityStepTypes && activityStepTypes.length > 0) {
        activitySteps = activitySteps.filter((step) =>
          activityStepTypes.includes(step.type)
        );
      }

      return activitySteps;
    }
  );

export const selectActivityStepById = (activityStepId: string) =>
  createSelector(selectAll, (entities: Activity[]) =>
    entities
      .reduce<ActivityStep[]>(
        (acc, activity) => acc.concat(activity.activitySteps),
        []
      )
      .find((activityStep) => activityStep.id === activityStepId)
  );

export const selectActivityStepResultById = (activityStepId: string) =>
  createSelector(
    selectAll,
    (entities: Activity[]) =>
      entities
        .reduce<ActivityStep[]>(
          (acc, activity) => acc.concat(activity.activitySteps),
          []
        )
        .find((activityStep) => activityStep.id === activityStepId).result
  );

export const selectMatterIdByActivityStepId = (activityStepId: string) =>
  createSelector(selectAll, (entities: Activity[]): string | undefined => {
    const activity = entities.find((activity) =>
      activity.activitySteps.some((step) => step.id === activityStepId)
    );

    if (!activity) {
      return undefined;
    }

    const matterIdFilter = activity.filters.find(
      (f) => f.key === "lfs:matterid"
    );
    return matterIdFilter ? matterIdFilter.value : undefined;
  });

export const selectQuoteActivitySteps = (quoteResults: QuoteResult[] = []) =>
  createSelector(
    selectAll,
    selectShowExpiredActivitySteps,
    (entities: Activity[], showExpired: boolean) => {
      let activitySteps = entities
        .reduce((acc, activity) => acc.concat(activity.activitySteps), [])
        .filter(
          (s) => s.type === ActivityStepType.Quote
        ) as QuoteActivityStep[];

      // Filter out deleted steps
      activitySteps = activitySteps.filter(
        (activityStep) => activityStep.isDeleted !== true
      );

      activitySteps = activitySteps.filter((activityStep) => {
        // Check if the quote has been accepted
        const hasResult =
          activityStep.result.values.quote === QuoteResult.Accepted ||
          activityStep.result.values.quote === QuoteResult.Declined;

        // If the quote has been accepted, we include it regardless of the expiration
        if (hasResult) {
          return true;
        }

        // If the quote has not been accepted, we check based on the expires property
        // and the showExpired toggle
        const isExpired =
          activityStep.expires && new Date(activityStep.expires) < new Date();
        return showExpired ? true : !isExpired;
      });

      // Additional logic for quoteResults if provided
      if (quoteResults.length) {
        activitySteps = activitySteps.filter((activityStep) =>
          quoteResults.includes(activityStep.result.values.quote)
        );
      }

      return activitySteps;
    }
  );

export const selectActionableQuoteActivitySteps = () =>
  createSelector(selectAll, (entities: Activity[]) => {
    const now = new Date();
    let activitySteps = entities
      .reduce((acc, activity) => acc.concat(activity.activitySteps), [])
      .filter((s) => s.type === ActivityStepType.Quote) as QuoteActivityStep[];

    // Filter out deleted steps
    activitySteps = activitySteps.filter(
      (activityStep) => activityStep.isDeleted !== true
    );

    // Filter to include only non-expired quote steps
    activitySteps = activitySteps.filter((activityStep) => {
      const expires = activityStep.expires
        ? new Date(activityStep.expires)
        : null;
      return !expires || expires > now;
    });

    // Filter to include only quote steps that have not been accepted or declined
    activitySteps = activitySteps.filter((activityStep) => {
      const hasResult =
        activityStep.result.values.quote === QuoteResult.Accepted ||
        activityStep.result.values.quote === QuoteResult.Declined;
      return !hasResult;
    });

    return activitySteps;
  });

export const selectLoading = () =>
  createSelector(selectActivitiesState, (s) => s.loading);
export const selectLoaded = () =>
  createSelector(selectActivitiesState, (s) => s.loaded);
