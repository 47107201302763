import { Injectable } from "@angular/core";
import { MatSidenav, MatSnackBar } from "@angular/material";
import { Notification } from "../../_state/models/notification.model";
import { NotificationComponent } from "./notification/notification.component";

@Injectable({ providedIn: "root" })
export class NotificationService {
  private sidenav: MatSidenav;

  constructor(private snackBar: MatSnackBar) {}

  public setSidenav(sidenav: MatSidenav): void {
    this.sidenav = sidenav;
  }

  public open(): void {
    this.sidenav.open();
  }

  public close(): void {
    this.sidenav.close();
  }

  public toggle(): void {
    this.sidenav.toggle();
  }

  notify(data: Notification): void {
    this.snackBar.openFromComponent(NotificationComponent, {
      data,
    });
  }
}
