import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Profile } from '../../../_state/models/profile.model';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    private readonly url = `${environment.presentation.url}/api/v1/Me`;

    constructor(private http: HttpClient) { }

    loadProfile(): Observable<Profile> {
        return this.http.get<Profile>(this.url);
    }
}
