import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as NotificationsActions from "../actions/notifications.actions";
import { Notification } from "../models/notification.model";
import * as AuthenticationActions from "../actions/authentication.actions";

export const featureKey = "notifications";

export interface State {
  notifications: Notification[];
}

export const defaultInitialState: State = {
  notifications: [],
};

const getInitialState = (userId: string): State => {
  try {
    const storedState = localStorage.getItem(`${featureKey}_${userId}`);
    if (storedState) {
      const parsedState: State = JSON.parse(storedState);
      if (parsedState) {
        // Apply notification type definition to each notification
        parsedState.notifications = parsedState.notifications.map(
          (notification: any) => {
            return {
              ...notification,
              timestamp: new Date(notification.timestamp),
            };
          }
        );
        return parsedState;
      } else {
        return defaultInitialState;
      }
    } else {
      return defaultInitialState;
    }
  } catch (error) {
    return defaultInitialState;
  }
};

const initialState: State = defaultInitialState;

const notificationsReducer = createReducer(
  initialState,
  on(AuthenticationActions.loadUserSuccess, (state, { userId }) => ({
    ...state,
    ...getInitialState(userId),
  })),
  on(NotificationsActions.addNotification, (state, { notification }) => ({
    ...state,
    notifications: [...state.notifications, notification],
  })),
  on(NotificationsActions.removeNotification, (state, { id }) => ({
    ...state,
    notifications: state.notifications.filter((n) => n.id !== id),
  })),
  on(NotificationsActions.markNotificationAsRead, (state, { id }) => ({
    ...state,
    notifications: state.notifications.map((notification) =>
      notification.id === id ? { ...notification, read: true } : notification
    ),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return notificationsReducer(state, action);
}

export const selectNotificationsState =
  createFeatureSelector<State>(featureKey);

export const selectAll = createSelector(
  selectNotificationsState,
  (state: State) =>
    state.notifications.sort(
      (a, b) => b.timestamp.getTime() - a.timestamp.getTime()
    )
);

export const selectUnreadTotal = createSelector(
  selectNotificationsState,
  (state: State) =>
    state.notifications && state.notifications.filter((n) => !n.read).length
);
