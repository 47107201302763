import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as UserSettingsActions from "../actions/user-settings.actions";
import { TaskListMode } from "../models/task-list-mode.model";
import * as AuthenticationActions from "../actions/authentication.actions";

export const featureKey = "user-settings";

export interface State {
  mode: TaskListMode;
}

const defaultInitialState: State = {
  mode: TaskListMode.Task,
};

const initialState: State = defaultInitialState;

const getInitialState = (userId: string): State => {
  try {
    const storedState = localStorage.getItem(`${featureKey}_${userId}`);
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      if (parsedState && parsedState.mode) {
        return parsedState;
      } else {
        return defaultInitialState;
      }
    } else {
      return defaultInitialState;
    }
  } catch (error) {
    return defaultInitialState;
  }
};

const userSettingsReducer = createReducer(
  initialState,
  on(AuthenticationActions.loadUserSuccess, (state, { userId }) => ({
    ...state,
    ...getInitialState(userId),
  })),
  on(UserSettingsActions.setPreferredTaskListMode, (state, { mode }) => ({
    ...state,
    mode,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return userSettingsReducer(state, action);
}

const selectUserSettingsState = createFeatureSelector<State>(featureKey);

export const selectPreferredTaskListMode = createSelector(
  selectUserSettingsState,
  (state: State) => state.mode
);
