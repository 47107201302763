import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationInterceptor implements HttpInterceptor {
    private apiUrls: string[];

    constructor(private authenticationService: AuthenticationService) {
        this.apiUrls = [environment.presentation.url, environment.document.url];
    }

    private isApiUrl(url: string, apiUrls: string[]): boolean {
        return apiUrls.some(apiUrl => url.startsWith(apiUrl));
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        var accessToken = this.authenticationService.accessToken;

        if (accessToken && this.isApiUrl(req.url, this.apiUrls) && !req.headers.has('Authorization')) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
        }

        return next.handle(req);
    }
}
