import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { handleError } from '../../_features/error/handle-effect-error.decorator';
import { MatterService } from '../../_features/me/services/matter.service';
import * as MatterNoteActions from '../actions/matter-note.actions';

@Injectable()
export class MatterNotesEffects {
    constructor(
        private actions$: Actions,
        private matters: MatterService,
    ) { }

    loadMatterNotes$ = createEffect(() => this.actions$
        .pipe(
            ofType(MatterNoteActions.loadMatterNotes),
            mergeMap(({ matterId }) => this.matters.loadMatterNotes(matterId)
                .pipe(
                    map(matterNotes => MatterNoteActions.loadMatterNotesSuccess({ matterNotes })),
                    handleError(MatterNoteActions.loadMatterNotesFailed)
                )
            )
        )
    );
}
