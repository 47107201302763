import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as MatterNotesActions from '../actions/matter-note.actions';
import { MatterNote } from '../models/matter-note.model';

export const featureKey = 'matter-notes';

export interface State extends EntityState<MatterNote> {
    loading: boolean;
    loaded: boolean;
}

export const adapter: EntityAdapter<MatterNote> = createEntityAdapter<MatterNote>();

export const initialState: State = adapter.getInitialState({
    loading: false,
    loaded: false
});

const matterNotesReducer = createReducer(
    initialState,
    on(MatterNotesActions.loadMatterNotes, (state) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(MatterNotesActions.loadMatterNotesSuccess, (state, action) =>
        adapter.addAll(action.matterNotes, {
            ...state,
            loading: false,
            loaded: true,
        })
    ),
    on(MatterNotesActions.loadMatterNotesFailed, (state) => ({
        ...state,
        loading: false
    })),
    on(MatterNotesActions.clearMatterNotes, (state) => adapter.removeAll({ ...state, loaded: false }))
);

export function reducer(state: State | undefined, action: Action) {
    return matterNotesReducer(state, action);
}

const selectMatterNotesState = createFeatureSelector<State>(featureKey);

const {
    selectEntities,
    selectAll,
} = adapter.getSelectors(selectMatterNotesState);

export const selectAllMatterNotes = () => selectAll;

export const selectMatterNotes = () => createSelector(
    selectEntities,
    (entities, props: { matterNoteId: number }) => entities[props.matterNoteId]
);

export const selectLoading = () => createSelector(
    selectMatterNotesState,
    (s: State) => s.loading
);

export const selectLoaded = () => createSelector(
    selectMatterNotesState,
    (s: State) => s.loaded
);
