import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ActivityStepService {
    constructor(http) {
        this.http = http;
        this.activityStepUrl = `${environment.presentation.url}/api/v2/ActivityStep`;
    }
    saveActivityStepResult(activityStepId, activityStepResult) {
        return this.http.patch(`${this.activityStepUrl}/${activityStepId}/Result/Update`, activityStepResult);
    }
    submitActivityStepResult(activityStepId, activityStepResult) {
        return this.http.patch(`${this.activityStepUrl}/${activityStepId}/Result/Submit`, activityStepResult, { headers: { 'Content-Type': 'application/json' } });
    }
    markStepAsInProgress(activityStepId) {
        return this.http.patch(`${this.activityStepUrl}/${activityStepId}/Status/InProgress`, {});
    }
}
ActivityStepService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivityStepService_Factory() { return new ActivityStepService(i0.ɵɵinject(i1.HttpClient)); }, token: ActivityStepService, providedIn: "root" });
