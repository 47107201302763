import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs/operators';
import { ProfileService } from '../../_features/me/services/me.service';
import * as ProfileActions from '../actions/profile.actions';

@Injectable()
export class ProfileEffects {
    constructor(
        private actions$: Actions,
        private service: ProfileService,
    ) { }

    loadProfile$ = createEffect(() => this.actions$
        .pipe(
            ofType(ProfileActions.loadProfile),
            concatMap(() => this.service.loadProfile()
                .pipe(map(profile => ProfileActions.loadProfileSuccess({ profile })))
            )
        )
    );
}
