import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MatterService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.url = `${environment.presentation.url}/api/v2/matter`;
    }
    loadMatters() {
        return this.httpClient.get(this.url, { params: { Includes: ['MilestonePlan', 'MilestoneResults', 'MilestonePlan.Milestones.Milestones'] } });
    }
    loadMatterNotes(matterId) {
        return this.httpClient.get(`${this.url}/${matterId}/note`);
    }
}
MatterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MatterService_Factory() { return new MatterService(i0.ɵɵinject(i1.HttpClient)); }, token: MatterService, providedIn: "root" });
