import { createAction, props } from '@ngrx/store';
import { ErrorActionProps } from '../../_features/error/error-action-props.model';
import { ActivityStepStatus } from '../models/activity-step-status.model';
import { ActivityStep, ActivityStepResult } from '../models/activity-step.model';
import { Activity } from '../models/activity.model';

export const addActivityStep = createAction(
    '[Activity] - Add Activity Step',
    props<{ activityStep: ActivityStep }>()
);

export const addActivities = createAction(
    '[Activity] - Add Activities',
    props<{ activities: Activity[] }>()
);

export const updateActivityStep = createAction(
    '[Activity] - Update Activity Step',
    props<{ activityStep: ActivityStep }>()
);

export const loadActivities = createAction(
    '[Activity] - Load Activities'
);

export const loadActivitiesSuccess = createAction(
    '[Activity] - Load Activities Success',
    props<{ activities: Activity[] }>()
);

export const loadActivitiesFailed = createAction(
    '[Activity] - Load Activities Failed',
    props<ErrorActionProps>()
);

export const updateActivityStepResult = createAction(
    '[Activity] - Update Activity Step Result',
    props<{ activityStep: ActivityStep, activityStepResult: ActivityStepResult }>()
);

export const updateActivityStepResultSuccess = createAction(
    '[Activity] - Update Activity Step Result Success',
    props<{ activityStep: ActivityStep, activityStepResult: ActivityStepResult }>()
);

export const updateActivityStepResultFailed = createAction(
    '[Activity] - Update Activity Step Result Failed',
    props<ErrorActionProps>()
);

export const patchActivityStepResult = createAction(
    '[Activity] - Patch Activity Step Result',
    props<{ activityStep: ActivityStep, activityStepResult: ActivityStepResult }>()
);

export const patchActivityStepResultSuccess = createAction(
    '[Activity] - Patch Activity Step Result Success',
    props<{ activityStep: ActivityStep, activityStepResult: ActivityStepResult }>()
);

export const patchActivityStepResultFailed = createAction(
    '[Activity] - Patch Activity Step Result Failed',
    props<ErrorActionProps>()
);

export const updateActivityStepResultValue = createAction(
    '[Activity] - Update Activity Step Result Values',
    props<{ activityStep: ActivityStep, key: string, value: any }>()
);

export const updateActivityStepResultValueSuccess = createAction(
    '[Activity] - Update Activity Step Result Values Success',
    props<{ activityStep: ActivityStep, key: string, value: string }>()
);

export const updateActivityStepResultValueFailed = createAction(
    '[Activity] - Update Activity Step Result Values Failed',
);

export const submitActivityStep = createAction(
    '[Activity] - Submit Activity Step',
    props<{ activityStep: ActivityStep, activityStepResult?: ActivityStepResult }>()
);

export const submitActivityStepSuccess = createAction(
    '[Activity] - Submit Activity Step Success',
    props<{ activityStep: ActivityStep }>()
);

export const submitActivityStepFailed = createAction(
    '[Activity] - Submit Activity Step Failed',
    props<ErrorActionProps>()
);

export const selectActivityStep = createAction(
    '[Activity] - Select Activity Step',
    props<{ activityStep: ActivityStep }>()
);

export const markStepAsInProgress = createAction(
    '[Activity] - Mark Step As In Progress',
    props<{ activityStep: ActivityStep }>()
);

export const markStepAsInProgressSuccess = createAction(
    '[Activity] - Mark Step As In Progress Success',
    props<{ activityStep: ActivityStep }>()
);

export const markStepAsInProgressFailed = createAction(
    '[Activity] - Mark Step As In Progress Failed',
    props<ErrorActionProps>()
);

export const addActivityStepStatusFilter = createAction(
    '[Activity] - Add Activity Step Statuses Filter',
    props<{ status: ActivityStepStatus }>()
);

export const addActivityStepStatusesFilter = createAction(
    '[Activity] - Add Activity Step Statuses Filter',
    props<{ statuses: ActivityStepStatus[] }>()
);

export const removeActivityStepStatusFilter = createAction(
    '[Activity] - Remove Activity Step Statuses Filter',
    props<{ status: ActivityStepStatus }>()
);

export const removeActivityStepStatusesFilter = createAction(
    '[Activity] - Remove Activity Step Statuses Filter',
    props<{ statuses: ActivityStepStatus[] }>()
);

export const showExpiredActivitySteps = createAction(
    '[Activity] - Show Expired Activity Steps',
    props<{ showExpiredActivitySteps: boolean }>()
);
