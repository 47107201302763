import { ErrorHandler, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';
import { ErrorEffects } from '../../_state/effects/error.effects';
import { AppErrorComponent } from './app-error/app-error.component';
import { Error400Component } from './error-code/400.component';
import { Error403Component } from './error-code/403.component';
import { Error404Component } from './error-code/404.component';
import { Error4XXComponent } from './error-code/4xx.component';
import { Error503Component } from './error-code/503.component';
import { Error5XXComponent } from './error-code/5xx.component';
import { ErrorDetailsComponent } from './error-details/error-details.component';
import { ErrorInlineComponent } from './error-inline/error-inline.component';
import { ErrorComponent } from './error/error.component';
import { GlobalErrorHandler } from './global-error-handler.service';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        RouterModule.forChild([
            {
                path: 'error/:status',
                component: ErrorComponent
            }
        ]),
        EffectsModule.forFeature([ErrorEffects]),
        MatDialogModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [ErrorInlineComponent],
    declarations: [
        ErrorComponent,
        Error5XXComponent,
        Error503Component,
        Error404Component,
        Error403Component,
        Error400Component,
        Error4XXComponent,
        AppErrorComponent,
        ErrorInlineComponent,
        ErrorDetailsComponent
    ],
    entryComponents: [ErrorDetailsComponent],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        }
    ],
})
export class ErrorModule { }
