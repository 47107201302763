import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { map, tap } from 'rxjs/operators';
import * as RouterActions from '../actions/router.actions';

@Injectable()
export class RouterEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
    ) { }

    navigate$ = createEffect(() => this.actions$
        .pipe(
            ofType(RouterActions.go),
            tap(routeData => this.router.navigate(routeData.commands, routeData.extras))
        ),
        { dispatch: false }
    );


    navigationStart$ = createEffect(() => this.actions$
        .pipe(
            ofType(ROUTER_NAVIGATION),
            map((action: RouterNavigationAction<RouterStateSnapshot>) => {
                if (action.payload.routerState.url.indexOf('auth') === -1) {
                    sessionStorage.setItem('current_nav', JSON.stringify(action.payload.routerState));
                }
            })
        ),
        { dispatch: false }
    );
}
