import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { CommunicationPreferencesService } from "../../_features/settings/components/communication-preferences/communication-preferences.service";
import * as CommunicationPreferenceActions from "../actions/communication-preferences.actions";

@Injectable()
export class CommunicationPreferenceEffects {
  constructor(
    private actions$: Actions,
    private communicationPreferencesService: CommunicationPreferencesService
  ) {}

  loadCommunicationPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationPreferenceActions.loadCommunicationPreference),
      switchMap(() =>
        this.communicationPreferencesService.loadCommunicationPreference().pipe(
          map((communicationPreference) =>
            CommunicationPreferenceActions.loadCommunicationPreferenceSuccess({
              communicationPreference,
            })
          ),
          catchError((error: Error) =>
            of(CommunicationPreferenceActions.loadCommunicationPreferenceError({ error }))
          )
        )
      )
    )
  );

  updateCommunicationPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationPreferenceActions.updateCommunicationPreference),
      switchMap(({ communicationPreference }) =>
        this.communicationPreferencesService
          .updateCommunicationPreference(communicationPreference)
          .pipe(
            map(() =>
              CommunicationPreferenceActions.updateCommunicationPreferenceSuccess()
            ),
            catchError((error: Error) =>
              of(
                CommunicationPreferenceActions.updateCommunicationPreferenceError({
                  error,
                })
              )
            )
          )
      )
    )
  );
}
