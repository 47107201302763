import { createAction, props } from "@ngrx/store";

export const loadCommunicationPreference = createAction(
  "[Communication Preferences] Load Communication Preference"
);

export const loadCommunicationPreferenceSuccess = createAction(
  "[Communication Preferences] Load Communication Preference Success",
  props<{ communicationPreference: number }>()
);

export const loadCommunicationPreferenceError = createAction(
  "[Communication Preferences] Load Communication Preference Error",
  props<{ error: Error }>()
);

export const updateCommunicationPreference = createAction(
  "[Communication Preferences] Update Communication Preference",
  props<{ communicationPreference: number }>()
);

export const updateCommunicationPreferenceSuccess = createAction(
  "[Communication Preferences] Update Communication Preference Success"
);

export const updateCommunicationPreferenceError = createAction(
  "[Communication Preferences] Update Communication Preference Error",
  props<{ error: Error }>()
);

export const reset = createAction("[Communication Preferences] Reset");