import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MatterNote } from '../../../_state/models/matter-note.model';
import { Matter } from '../../../_state/models/matter.model';

@Injectable({ providedIn: 'root' })
export class MatterService {
    private readonly url = `${environment.presentation.url}/api/v2/matter`;

    constructor(private httpClient: HttpClient) { }

    loadMatters() {
        return this.httpClient.get<Matter[]>(this.url, { params: { Includes: ['MilestonePlan', 'MilestoneResults', 'MilestonePlan.Milestones.Milestones'] } });
    }

    loadMatterNotes(matterId: string) {
        return this.httpClient.get<MatterNote[]>(`${this.url}/${matterId}/note`);
    }
}
