import { MatSnackBar } from "@angular/material";
import { NotificationComponent } from "./notification/notification.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export class NotificationService {
    constructor(snackBar) {
        this.snackBar = snackBar;
    }
    setSidenav(sidenav) {
        this.sidenav = sidenav;
    }
    open() {
        this.sidenav.open();
    }
    close() {
        this.sidenav.close();
    }
    toggle() {
        this.sidenav.toggle();
    }
    notify(data) {
        this.snackBar.openFromComponent(NotificationComponent, {
            data,
        });
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.MatSnackBar)); }, token: NotificationService, providedIn: "root" });
