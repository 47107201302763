import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  Renderer2,
} from "@angular/core";
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as NotificationsActions from "../../../_state/actions/notifications.actions";
import {
  Notification,
  NotificationType,
} from "../../../_state/models/notification.model";
import * as fromNotifications from "../../../_state/reducers/notifications.reducer";
import { AuthenticationService } from "../../../_features/authentication/authentication.service";

@Component({
  selector: "lfs-notification",
  styleUrls: ["./notification.component.scss"],
  templateUrl: "./notification.component.html",
})
export class NotificationComponent implements OnInit {
  progress = 0;
  public duration: number | undefined;
  private interval: any;
  private intervalTime: number;

  constructor(
    @Optional() @Inject(MAT_SNACK_BAR_DATA) public notification: Notification,
    private snackBarRef: MatSnackBarRef<NotificationComponent>,
    private renderer: Renderer2,
    private el: ElementRef,
    private store: Store<fromNotifications.State>,
    private router: Router,
    private authenicationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.duration = this.notification && this.notification.duration;
    if (this.duration) {
      this.intervalTime = this.duration / 100;
      this.incrementProgress();
    }

    // Add event listeners to the parent element
    const parentElement = this.el.nativeElement.parentElement;
    this.renderer.listen(parentElement, "mouseenter", () =>
      this.pauseProgress()
    );
    this.renderer.listen(parentElement, "mouseleave", () =>
      this.resumeProgress()
    );
  }

  incrementProgress(): void {
    this.interval = setInterval(() => {
      this.progress += 1;
      if (this.progress >= 100) {
        clearInterval(this.interval);
        this.snackBarRef.dismiss();
      }
    }, this.intervalTime);
  }

  pauseProgress(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  resumeProgress(): void {
    if (this.duration) {
      this.incrementProgress();
    }
  }

  handleAction(notification: Notification): void {
    this.markAsRead(notification);

    if (notification.action && notification.action.route) {
      this.router.navigate(
        notification.action.route.commands,
        notification.action.route.extras
      );
    }
  }

  markAsRead(notification: Notification) {
    if (notification.read) {
      return;
    }

    this.store.dispatch(
      NotificationsActions.markNotificationAsRead({
        id: notification.id,
        userId: this.authenicationService.sub,
      })
    );
  }

  getColor(): string {
    switch (this.notification.type) {
      case NotificationType.Success:
        return "primary";
      case NotificationType.Error:
        return "warn";
      case NotificationType.Warning:
        return "warn";
      case NotificationType.Info:
        return "accent";
      default:
        return "primary";
    }
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
