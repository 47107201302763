import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthConfig, JwksValidationHandler, OAuthModule, OAuthStorage, ValidationHandler } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { initaliseAuthentication } from './authentication-initializer';
import { AuthenticationInterceptor } from './authentication.interceptor';
import { AuthenticationService } from './authentication.service';

export const authConfigFactory = () => {
    return {
        oidc: true,
        issuer: environment.stsUri,
        clientId: environment.clientId,
        redirectUri: `${window.location.origin}`,
        logoutUrl: window.location.origin,
        responseType: 'code',
        scope: environment.scopes,
        silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
        requireHttps: true,
        strictDiscoveryDocumentValidation: true,

        useSilentRefresh: true,
        silentRefreshTimeout: 2500,
        timeoutFactor: 0.75,
        clearHashAfterLogin: false,
        nonceStateSeparator: 'semicolon',

        showDebugInformation: !environment.production,
    } as AuthConfig;
};

@NgModule({
    imports: [OAuthModule.forRoot()],
    providers: [
        {
            provide: AuthConfig,
            useFactory: authConfigFactory,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initaliseAuthentication,
            deps: [AuthenticationService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
        },
        { provide: ValidationHandler, useClass: JwksValidationHandler },
        { provide: OAuthStorage, useValue: localStorage },
    ],
})
export class AuthenticationModule { }
