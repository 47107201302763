export enum ActivityStepStatus {
    NotSet = 0,
    ReadyToExecute = 1,
    InProgress = 2,
    Completed = 3,
    Failed = 4,
    NotReady = 5,
    Faulted = 6,
    Approved = 7,
    Cancelled = 8,
    Rejected = 9,
    Returned = 10
}

export const ActivityStepStatusDescriptions: { [key in ActivityStepStatus]: string } = {
    [ActivityStepStatus.NotSet]: "Not Set",
    [ActivityStepStatus.ReadyToExecute]: "Ready To Execute",
    [ActivityStepStatus.InProgress]: "In Progress",
    [ActivityStepStatus.Completed]: "Completed",
    [ActivityStepStatus.Failed]: "Failed",
    [ActivityStepStatus.NotReady]: "Not Ready",
    [ActivityStepStatus.Faulted]: "Faulted",
    [ActivityStepStatus.Approved]: "Approved",
    [ActivityStepStatus.Cancelled]: "Cancelled",
    [ActivityStepStatus.Rejected]: "Rejected",
    [ActivityStepStatus.Returned]: "Returned"
};
