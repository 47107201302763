import { AuthenticationService } from './authentication.service';

export function initaliseAuthentication(authenticationService: AuthenticationService) {
    return () => {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('t')) {
            return authenticationService.initializeTemporaryToken(urlParams.get('t')!);
        } else {
            return authenticationService.runInitialLoginSequence();
        }
    }
}
