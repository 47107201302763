import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { ActivityStepStatus } from "../../../../_state/models/activity-step-status.model";
import { ActivityStepType } from "../../../../_state/models/activity-step.model";
import { Includes } from "../../../../_state/models/includes.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ActivityService {
    constructor(http) {
        this.http = http;
        this.activityUrl = `${environment.presentation.url}/api/v2/activity`;
    }
    getActivities() {
        let params = new HttpParams();
        const includes = [Includes.Filters];
        const statuses = [
            ActivityStepStatus.NotSet,
            ActivityStepStatus.InProgress,
            ActivityStepStatus.Completed,
            ActivityStepStatus.Faulted,
            ActivityStepStatus.Approved,
        ];
        const supportedTasks = [
            ActivityStepType.Form,
            ActivityStepType.IdCheck,
            ActivityStepType.Payment,
            ActivityStepType.Quote,
        ];
        params = params.append("returnMetadata", true.toString());
        params = params.append("returnStepResult", true.toString());
        params = params.append("returnStepMetadata", true.toString());
        params = params.append("returnStepHistoryResult", true.toString());
        params = params.append("includeExpired", true.toString());
        includes.forEach((include) => (params = params.append("includes", include)));
        statuses.forEach((status) => (params = params.append("stepStatuses", status.toString())));
        supportedTasks.forEach((type) => (params = params.append("stepTypes", type)));
        return this.http.get(this.activityUrl, { params });
    }
}
ActivityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivityService_Factory() { return new ActivityService(i0.ɵɵinject(i1.HttpClient)); }, token: ActivityService, providedIn: "root" });
