import { createAction, props } from '@ngrx/store';
import { ErrorActionProps } from '../../_features/error/error-action-props.model';
import { MatterNote } from '../models/matter-note.model';

export const loadMatterNotes = createAction(
    '[Me] - Load Matter Notes',
    props<{ matterId: string }>()
);

export const loadMatterNotesSuccess = createAction(
    '[Me] - Load Matter Notes Success',
    props<{ matterNotes: MatterNote[] }>()
);

export const loadMatterNotesFailed = createAction(
    '[Me] - Load Matter Notes Failed',
    props<ErrorActionProps>()
);

export const clearMatterNotes = createAction(
    '[Me] - Clear Matter Notes'
);
