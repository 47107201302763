import { createAction, props } from '@ngrx/store';
import { ErrorActionProps } from '../../_features/error/error-action-props.model';
import { Matter } from '../models/matter.model';

export const loadMatters = createAction(
    '[Me] - Load Matters'
);

export const loadMattersSuccess = createAction(
    '[Me] - Load Matters Success',
    props<{ matters: Matter[] }>()
);

export const loadMattersFailed = createAction(
    '[Me] - Load Matters Failed',
    props<ErrorActionProps>()
);

export const addMatters = createAction(
    '[Me] - Add Matters',
    props<{ matters: Matter[] }>()
);
