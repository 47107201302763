import { Component, OnInit } from '@angular/core';
import { BaseErrorComponent } from '../error-base/error-base.component';
import { faDizzy } from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'lfs-error-400',
    templateUrl: '../error-base/error-base.component.html',
    styleUrls: ['../error-base/error-base.component.scss']
})

export class Error400Component extends BaseErrorComponent implements OnInit {
    constructor() {
        super();
    }

    ngOnInit() {
        this.icon = faDizzy;
        this.title = 'Bad Request';
        this.message = 'Our servers could not understand the request your web browser sent.';
        this.helpItems = [
            'If you have copied this link from somewhere check that it has copied correctly.',
            'If you have arrived here from a bookmark please go to the <a href="/">home page</a> and update your bookmarks.'
        ];
    }
}
