import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { ActivityStepStatus } from "../../../../_state/models/activity-step-status.model";
import { ActivityStepType } from "../../../../_state/models/activity-step.model";
import { Activity } from "../../../../_state/models/activity.model";
import { Includes } from "../../../../_state/models/includes.model";

@Injectable({ providedIn: "root" })
export class ActivityService {
  constructor(private http: HttpClient) {}

  private readonly activityUrl = `${environment.presentation.url}/api/v2/activity`;

  getActivities() {
    let params = new HttpParams();

    const includes = [Includes.Filters];
    const statuses = [
      ActivityStepStatus.NotSet,
      ActivityStepStatus.InProgress,
      ActivityStepStatus.Completed,
      ActivityStepStatus.Faulted,
      ActivityStepStatus.Approved,
    ];
    const supportedTasks = [
      ActivityStepType.Form,
      ActivityStepType.IdCheck,
      ActivityStepType.Payment,
      ActivityStepType.Quote,
    ];

    params = params.append("returnMetadata", true.toString());
    params = params.append("returnStepResult", true.toString());
    params = params.append("returnStepMetadata", true.toString());
    params = params.append("returnStepHistoryResult", true.toString());
    params = params.append("includeExpired", true.toString());
    includes.forEach(
      (include) => (params = params.append("includes", include))
    );
    statuses.forEach(
      (status) => (params = params.append("stepStatuses", status.toString()))
    );
    supportedTasks.forEach(
      (type) => (params = params.append("stepTypes", type))
    );

    return this.http.get<Activity[]>(this.activityUrl, { params });
  }
}
