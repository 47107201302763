import { createAction, props } from "@ngrx/store";
import { Notification } from "../models/notification.model";
import { UserIdAction } from "./interfaces/user-id";

export const actionGroup = "[Notifications]";

export const addNotification = createAction(
  `${actionGroup} Add Notification`,
  props<UserIdAction & { notification: Notification }>()
);

export const markNotificationAsRead = createAction(
  `${actionGroup} Mark Notification As Read`,
  props<UserIdAction & { id: string }>()
);

export const removeNotification = createAction(
  `${actionGroup} Remove Notification`,
  props<UserIdAction & { id: string }>()
);
