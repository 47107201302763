import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as MatterActions from '../actions/matter.actions';
import { Matter } from '../models/matter.model';

export const featureKey = 'matter';

export interface State extends EntityState<Matter> {
    loading: boolean;
    loaded: boolean;
}

export const adapter: EntityAdapter<Matter> = createEntityAdapter<Matter>();

export const initialState: State = adapter.getInitialState({
    loading: false,
    loaded: false
});

const matterReducer = createReducer(
    initialState,
    on(MatterActions.loadMatters, (state) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(MatterActions.loadMattersSuccess, (state, action) =>
        adapter.addAll(action.matters, {
            ...state,
            loading: false,
            loaded: true,
        })
    ),
    on(MatterActions.loadMattersFailed, (state) => ({
        ...state,
        loading: false
    })),
    on(MatterActions.addMatters, (state, action) =>
        adapter.addMany(action.matters, state)
    )
);

export function reducer(state: State | undefined, action: Action) {
    return matterReducer(state, action);
}

const selectMattersState = createFeatureSelector<State>(featureKey);

const {
    selectEntities,
    selectAll,
} = adapter.getSelectors(selectMattersState);

export const selectMatters = () => selectAll;

export const selectMatter = (matterId: number) => createSelector(
    selectEntities,
    (entities) => entities[matterId]
);

export const selectMatterDescription = (matterId: number) => createSelector(
    selectEntities,
    (entities) => {
        var matter = entities[matterId];
        return matter ? matter.description : undefined;
    }
);

export const selectLoading = () => createSelector(
    selectMattersState,
    (s: State) => s.loading
);

export const selectLoaded = () => createSelector(
    selectMattersState,
    (s: State) => s.loaded
);
