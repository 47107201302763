import { Routes } from "@angular/router";
const ɵ0 = () => import("./_features/me/me.module.ngfactory").then((m) => m.MeModuleNgFactory), ɵ1 = () => import("./_features/privacy/privacy.module.ngfactory").then((m) => m.PrivacyModuleNgFactory), ɵ2 = () => import("./_features/settings/settings.module.ngfactory").then((m) => m.SettingsModuleNgFactory);
export const routes = [
    {
        path: "",
        loadChildren: ɵ0,
    },
    {
        path: "privacy",
        loadChildren: ɵ1,
    },
    {
        path: "settings",
        loadChildren: ɵ2,
    },
    {
        path: "**",
        redirectTo: "error/404",
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
