import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { handleError } from '../../_features/error/handle-effect-error.decorator';
import { MatterService } from '../../_features/me/services/matter.service';
import * as MatterActions from '../actions/matter.actions';

@Injectable()
export class MatterEffects {
    constructor(
        private actions$: Actions,
        private matters: MatterService,
    ) { }

    loadMatters$ = createEffect(() => this.actions$
        .pipe(
            ofType(MatterActions.loadMatters),
            mergeMap(() =>
                this.matters.loadMatters()
                    .pipe(
                        map(matters => MatterActions.loadMattersSuccess({ matters })),
                        handleError(MatterActions.loadMattersFailed)
                    )
            )
        )
    );
}
