import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as ConnectivityActions from '../actions/connectivity.actions';

export const featureKey = 'connectivity';

export interface State {
    isConnected: boolean;
}

export const initialConnectivityState: State = {
    isConnected: true
};

const connectivityReducer = createReducer(
    initialConnectivityState,
    on(ConnectivityActions.connectionLost, state => ({ ...state, isConnected: false })),
    on(ConnectivityActions.connectionRestored, state => ({ ...state, isConnected: true }))
);

export function reducer(state: State | undefined, action: Action) {
    return connectivityReducer(state, action);
}

const selectConnectivityState = createFeatureSelector<State>(featureKey);

export const selectIsConnected = () => createSelector(
    selectConnectivityState,
    (state: State) => state.isConnected
);


