import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

export const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./_features/me/me.module").then((m) => m.MeModule),
  },
  {
    path: "privacy",
    loadChildren: () =>
      import("./_features/privacy/privacy.module").then((m) => m.PrivacyModule),
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./_features/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: "**",
    redirectTo: "error/404",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: "always",
      onSameUrlNavigation: "reload",
    }),
  ],
})
export class AppRoutingModule {}
