import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localeEnGb from "@angular/common/locales/en-GB";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatBadgeModule, MatSnackBarModule } from "@angular/material";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { AuthenticationModule } from "./_features/authentication/authentication.module";
import { ErrorModule } from "./_features/error/error.module";
import { ActivityEffects } from "./_state/effects/activity.effects";
import { CommunicationPreferenceEffects } from "./_state/effects/communication-preference.effects";
import { MatterNotesEffects } from "./_state/effects/matter-notes.effects";
import { MatterEffects } from "./_state/effects/matter.effects";
import { NotificationsEffects } from "./_state/effects/notifications.effects";
import { ProfileEffects } from "./_state/effects/profile.effects";
import { RouterEffects } from "./_state/effects/router.effects";
import { metaReducers } from "./_state/reducers/meta.reducer";
import * as fromRoot from "./_state/reducers/root.reducer";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LfsCommonModule } from "./common/lfs-common.module";
import { LocaleService } from "./common/services/locale.service";
import { WINDOW } from "./common/utils/window";
import { NavigationService } from "./routing/navigation.service";

registerLocaleData(localeEnGb);

@NgModule({
    declarations: [AppComponent],
    imports: [
        AuthenticationModule,
        FlexLayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatIconModule,
        HttpClientModule,
        FontAwesomeModule,
        MatSidenavModule,
        MatBadgeModule,
        MatListModule,
        BrowserAnimationsModule,
        RouterModule,
        ErrorModule,
        AppRoutingModule,
        PdfViewerModule,
        MatSnackBarModule,
        LfsCommonModule,
        StoreModule.forRoot(fromRoot.reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        EffectsModule.forRoot([
            RouterEffects,
            ProfileEffects,
            CommunicationPreferenceEffects,
            NotificationsEffects,
            MatterEffects,
            MatterNotesEffects,
            ActivityEffects,
        ]),
        StoreRouterConnectingModule.forRoot({ stateKey: "router" }),
        StoreDevtoolsModule.instrument({ maxAge: 25 }),
    ],
    providers: [
        NavigationService,
        {
            provide: LOCALE_ID,
            deps: [LocaleService],
            useFactory: (service: LocaleService) => service.getLanguage(),
        },
        {
            provide: WINDOW,
            useValue: window,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
