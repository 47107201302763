import { createAction, props } from '@ngrx/store';
import { Profile } from '../models/profile.model';

export const actionGroup = '[Profile]';

export const loadProfile = createAction(
    `${actionGroup} - Load Profile`
);

export const loadProfileSuccess = createAction(
    `${actionGroup} - Load Profile Success`,
    props<{ profile: Profile }>()
);

