import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Injectable()
export class NavigationService {
    constructor(private router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.handleNavigationStart(event);
            }
        });
    }

    private handleNavigationStart(event: NavigationStart): void {
        const url = event.url;
        if (url.startsWith('/&iss')) {
            const newUrl = url.replace('/&iss', '/');
            this.router.navigateByUrl(newUrl, { replaceUrl: true });
        }
    }
}
