import { Action, ActionReducer, MetaReducer } from "@ngrx/store";
import * as fromUserSettings from "./user-settings.reducer";
import * as fromNotifications from "./notifications.reducer";
import * as UserSettingsActions from "../actions/user-settings.actions";
import * as NotificationsActions from "../actions/notifications.actions";
import { UserIdAction } from "../actions/interfaces/user-id";

const actionGroups = {
  [UserSettingsActions.actionGroup]: fromUserSettings.featureKey,
  [NotificationsActions.actionGroup]: fromNotifications.featureKey,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action: Action & UserIdAction) {
    const nextState = reducer(state, action);

    const actionGroup = Object.keys(actionGroups).find((group) =>
      action.type.startsWith(group)
    );
    const userActionId = action.userId;

    if (actionGroup && userActionId) {
      try {
        const featureKey = actionGroups[actionGroup];
        localStorage.setItem(
          `${featureKey}_${userActionId}`,
          JSON.stringify(nextState[featureKey])
        );
      } catch (error) {
        console.error("Failed to save state to localStorage", error);
      }
    } 

    return nextState;
  };
}

export const metaReducers: MetaReducer<any>[] = [localStorageSyncReducer];
