import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorData } from '../../../_state/models/error.model';

@Component({
    selector: 'lfs-error-details',
    templateUrl: 'error-details.component.html',
    styleUrls: ['error-details.component.scss']
})

export class ErrorDetailsComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public errorDetails: {
        useGlobalErrorPage: boolean;
        logError: boolean;
        error: ErrorData;
    } | {
        useGlobalErrorPage: boolean;
        logError: boolean;
        error: ErrorData;
    }[]) { }

    ngOnInit() { }
}
