import { ActivityStep, ActivityStepResult } from './activity-step.model';
import { PaymentIntentStatus } from './payment-intent.model';

export interface PaymentActivityStep extends ActivityStep {
    result: PaymentActivityStepResult;
    metadata: PaymentActivityMetaData;
}

export interface PaymentActivityStepResult extends ActivityStepResult {
    values: {
        shopperIpAddress: string;
        shopperSessionId: string;
        shopperAcceptHeader: string;
        shopperUserAgent: string;
        paymentIntentStatus: PaymentIntentStatus;
    }
}

export interface PaymentActivityMetaData {
    paymentRequest: {
        paymentProcessor: PaymentProcessor;
        amount: number;
        currency: string;
        reference: string;
        description: string;
    },
    clientSecret: string
}

export enum PaymentProcessor {
    STRIPE = 1,
    PAY360 = 2
}
