import { Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export abstract class BaseErrorComponent {
    @Input()
    status: number;

    icon: IconDefinition;
    title: string;
    message: string;
    helpItems: string[];
    iconClass: string;
}
