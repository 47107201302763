import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import * as NotificationsActions from "../../../_state/actions/notifications.actions";
import { Notification } from "../../../_state/models/notification.model";
import * as fromNotifications from "../../../_state/reducers/notifications.reducer";
import { NotificationService } from "../notification.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../_features/authentication/authentication.service";

@Component({
  selector: "lfs-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent {
  notifications$ = this.store.select(fromNotifications.selectAll);

  constructor(
    private store: Store<fromNotifications.State>,
    private notificationService: NotificationService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  onNotificationClick(notification: Notification) {
    this.closeSidebar();
    this.markAsRead(notification);

    if (notification.action && notification.action.route) {
      this.router.navigate(
        notification.action.route.commands,
        notification.action.route.extras
      );
    }
  }

  removeNotification(notification: Notification) {
    this.store.dispatch(
      NotificationsActions.removeNotification({
        id: notification.id,
        userId: this.authenticationService.sub,
      })
    );
  }

  closeSidebar() {
    this.notificationService.close();
  }

  markAsRead(notification: Notification) {
    if (notification.read) {
      return;
    }

    this.store.dispatch(
      NotificationsActions.markNotificationAsRead({
        id: notification.id,
        userId: this.authenticationService.sub,
      })
    );
  }
}
