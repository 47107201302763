import { Injectable, NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  from,
  interval,
} from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromRoot from "../../_state/reducers/root.reducer";
import * as AuthenticationActions from "../../_state/actions/authentication.actions";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private refreshSubscription: Subscription;

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private temporaryToken: string | null = null;
  public get isTemporary() {
    return this.temporaryToken !== null;
  }

  public initializeTemporaryToken(token: string) {
    this.temporaryToken = token;
    this.isAuthenticatedSubject$.next(true);
    this.isDoneLoadingSubject$.next(true);
  }

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values) => values.every((b) => b)));

  constructor(
    private oauthService: OAuthService,
    private ngZone: NgZone,
    private authConfig: AuthConfig,
    private store: Store<fromRoot.State>
  ) {}

  public runInitialLoginSequence(): Promise<boolean> {
    if (location.hash) {
      console.log("Encountered hash fragment, plotting as table...");
      console.table(
        location.hash
          .slice(1)
          .split("&")
          .map((kvp) => kvp.split("="))
      );
    }

    return this.oauthService
      .loadDiscoveryDocumentAndLogin()
      .then((hasReceivedTokens: boolean) => {
        if (hasReceivedTokens) {
          this.initialiseRefresh();
        }

        if (this.oauthService.hasValidAccessToken()) {
          const userId = this.oauthService.getIdentityClaims()['sub'];
          this.store.dispatch(AuthenticationActions.loadUserSuccess({ userId }));
  
          this.isAuthenticatedSubject$.next(true);
        } else {
          this.login();
        }

        this.isDoneLoadingSubject$.next(true);
        return this.isAuthenticatedSubject$.value;
      });
  }

  initialiseRefresh(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }

    const refreshInterval = this.calculateRefreshInterval();

    this.ngZone.runOutsideAngular(() => {
      this.refreshSubscription = interval(refreshInterval)
        .pipe(
          switchMap(() => this.refreshToken()),
          catchError((err) => {
            this.login();
            throw err;
          })
        )
        .subscribe();
    });
  }

  private calculateRefreshInterval(): number {
    const tokenExpiry = this.getAccessTokenExpiration();

    if (!tokenExpiry) {
      return 0;
    }

    const timeUntilExpiry = tokenExpiry - Date.now();

    const refreshTime = timeUntilExpiry * this.authConfig.timeoutFactor;

    return Math.max(refreshTime, 0);
  }

  private refreshToken(): Promise<object> {
    return this.oauthService.refreshToken().catch((err) => {
      this.login();
      debugger;
      throw err;
    });
  }

  public loadUserProfile() {
    if (this.isTemporary) {
      return Promise.resolve(null);
    } else {
      return from(this.oauthService.loadUserProfile());
    }
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(
      encodeURIComponent(targetUrl || window.location.pathname)
    );
  }

  public logout() {
    this.oauthService.logOut();
  }
  public hasValidToken() {
    return this.oauthService.hasValidAccessToken() || !!this.temporaryToken;
  }
  public getAccessTokenExpiration() {
    return this.oauthService.getAccessTokenExpiration();
  }

  public get accessToken() {
    return this.temporaryToken || this.oauthService.getAccessToken();
  }
  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }
  public get idToken() {
    return this.oauthService.getIdToken();
  }
  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }
  public get sub() {
    return this.identityClaims["sub"];
  }
}
