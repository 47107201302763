import { NavigationExtras } from '@angular/router';
import { createAction, props } from '@ngrx/store';

export const go = createAction(
    '[Router] - Go',
    props<{
        commands: any[],
        query?: object,
        extras?: NavigationExtras
    }>()
);
