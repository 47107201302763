import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

const clientInfoRequest = new XMLHttpRequest();

clientInfoRequest.open('GET', environment.discovery.url, true);
clientInfoRequest.send();

clientInfoRequest.onreadystatechange = function readyStateChanged() {
    if (this.readyState === 4) {
        if (this.status === 200) {
            const clientConfig = JSON.parse(this.responseText);
            environment.clientId = clientConfig.clientId;
            environment.stsUri = clientConfig.stsUri;
            environment.title = clientConfig.title || 'No Title Set';
            environment.useMilestones = clientConfig.useMilestones;
            environment.useQuotes = clientConfig.useQuotes;
            environment.usePrivacySettings = clientConfig.usePrivacySettings;
            environment.supportedCommunicationPreference = clientConfig.supportedCommunicationPreference;
        }
        environment.statusCode = this.status;

        platformBrowserDynamic().bootstrapModule(AppModule)
            .catch(err => console.log(err));
    }
};


