<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="center center">
    {{ notification?.title }}
    <div fxFlex></div>
    <button
      *ngIf="notification.action; else noActionTemplate"
      mat-button
      (click)="handleAction(notification)"
    >
      {{ notification.action.label }}
  </button>
    <ng-template #noActionTemplate>
      <button mat-button (click)="close()">DISMISS</button>
    </ng-template>
  </div>
  <mat-progress-bar
    *ngIf="duration"
    [color]="getColor()"
    mode="determinate"
    [value]="progress"
  ></mat-progress-bar>
</div>
