/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../error-base/error-base.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./403.component";
var styles_Error403Component = [i0.styles];
var RenderType_Error403Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Error403Component, data: {} });
export { RenderType_Error403Component as RenderType_Error403Component };
function View_Error403Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
export function View_Error403Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["size", "6x"]], [[8, "className", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(3, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", "\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Error403Component_1)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.icon; var currVal_4 = "6x"; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_6 = _co.helpItems; _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.iconClass; var currVal_2 = i1.ɵnov(_v, 3).renderedIconHTML; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_5 = _co.message; _ck(_v, 5, 0, currVal_5); }); }
export function View_Error403Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lfs-error-403", [], null, null, null, View_Error403Component_0, RenderType_Error403Component)), i1.ɵdid(1, 114688, null, 0, i6.Error403Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Error403ComponentNgFactory = i1.ɵccf("lfs-error-403", i6.Error403Component, View_Error403Component_Host_0, { status: "status" }, {}, []);
export { Error403ComponentNgFactory as Error403ComponentNgFactory };
